import { getDocumentRoute, updateViewCountRoute } from 'adapters/routes';
import { documentUploadRoute } from '../../routes/apiRoutes';

/**
 * @name documentRequests
 * @description Function used to make request to the document endpoints
 * @param requestor
 * @returns {object} response
 */
export const documentRequests = (requestor) => ({
  /**
   * @name uploadNewDocument
   * @description Function used to make request to create a new document
   * @param {object} data
   * @returns {Promise<any>} response
   */
  uploadNewDocument: async (data) => {
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('category', data.category);
    formData.append('course_units', data.course_units);
    formData.append('custom_name', data.custom_name);
    formData.append('file_type,', data.file_type);
    formData.append('academic_year', data.academic_year);
    formData.append('video_title', data.video_title);
    formData.append('num_of_pages', data.num_of_pages);
    formData.append('video_url', data.video_url);
    formData.append('video_duration', data.video_duration);

    const { url } = documentUploadRoute();
    const response = await requestor({
      method: 'POST',
      url,
      data: formData,
    });
    return response;
  },

  /**
   * @name updateDocument
   * @description Function used to make request to update document
   * @param {object} data
   * @returns {Promise<any>} response
   */
  updateDocument: async (data, documentId) => {
    const url = `/files/updateFileDetails/${documentId}`;
    const response = await requestor({
      method: 'PATCH',
      url,
      data,
    });
    return response;
  },

  /**
   * @name updateViewCount
   * @description Function used to update the view count of a document
   * @param {string} documentId
   * @returns {Promise<any>} response
   */
  updateViewCount: async (documentId) => {
    const { url } = updateViewCountRoute(documentId);
    const response = await requestor({
      method: 'PUT',
      url,
    });
    return response;
  },

  /**
   * @name get - handle all get requests for documents
   */
  get: {
    /**
     * @name documentDetails
     * @description Function used to get details of a document
     * @param {string} documentId
     * @returns {object} response
     */
    documentDetails: async (documentId) => {
      const { url } = getDocumentRoute(documentId);
      const response = await requestor({
        method: 'GET',
        url,
      });
      return response;
    },

    /**
     * @name getUserUploadedDocuments
     * @description Function used to get all documents uploaded by a user
     * @returns {object} response
     */
    getUserUploadedDocuments: async () => {
      const url = `/files/user/uploads`;
      const response = await requestor({
        method: 'GET',
        url,
      });
      return response;
    },
  },
});

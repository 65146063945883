import React from 'react';
import NavigationItem from '../FooterMenu/NavigationItem';

const AboutUsMenu = () => (
  <>
    <NavigationItem itemLink='/our-creative-team' itemTitle='Our Team' valid />
    <NavigationItem itemLink='/' itemTitle='Privacy Policy' />
    <NavigationItem itemLink='/dashboard/volunteer' itemTitle='Volunteers' valid />
    <NavigationItem itemLink='/' itemTitle='Vacancies' />
    <NavigationItem itemLink='/' itemTitle='Terms & Conditions' />
  </>
);
export default AboutUsMenu;

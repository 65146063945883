import React from 'react';
import { useSelector } from 'react-redux';

//= ==COMPONENT IMPORTS===
import { Avatar } from '@mui/material';
import classes from './UserProfileCard.module.css';
import UserName from './UserName';

const UserProfilePicture = () => {
  const CurrentUser = useSelector((state) => state.auth.user);
  const hasProfilePicture = !!(CurrentUser.image && CurrentUser.image.length > 5);
  return (
    <div className={classes.gpa__user_profile_card_picture_area_wrapper}>
      {hasProfilePicture ? (
        <Avatar
          src={CurrentUser.image}
          alt={CurrentUser.first_name + CurrentUser.last_name}
          sx={{
            width: '100%',
            height: '100%',
          }}
        />
      ) : (
        <div className={classes.gpa__user_profile_card_picture_user_name}>
          <UserName />
        </div>
      )}
    </div>
  );
};
export default UserProfilePicture;

import { useSelector } from 'react-redux';

const UserName =()=>{
    const  { user, isLoggedIn } =useSelector(state => state.auth);
    let userName ='';
    if(isLoggedIn){
        userName = user.first_name.charAt(0) + user.last_name.charAt(0);
    }
    return userName.toUpperCase();
    
}
export default UserName;
import React from "react";
import NavigationItem from "../FooterMenu/NavigationItem";

const HelpCenterMenu = () => (
    <>
      <NavigationItem itemLink="/" itemTitle="Agents Contacts" />
      <NavigationItem itemLink="/" itemTitle="FAQs" />
    </>
  );
export default HelpCenterMenu;

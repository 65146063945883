import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

import { GPA_APIS } from 'adapters';
import { getInitialMode, toggleMode } from '../../../store/Slices/themeSlice';
import classes from './DarkModeToggle.module.css';

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#777777' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
    backgroundImage:
      theme.palette.mode === 'light'
        ? `url('/assets/icons/sun.png')`
        : `url('/assets/icons/moon.png')`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const DarkModeToggle = () => {
  const darkMode = useSelector((state) => state.theme.darkMode);
  const dispatch = useDispatch();

  const toggleDarkModeHandler = async () => {
    dispatch(toggleMode(!darkMode));
    const data = {
      theme: darkMode ? 'light' : 'dark',
    };
    await GPA_APIS.auth.updateUserSettings(data);
  };

  useEffect(() => {
    dispatch(getInitialMode());
  }, [dispatch]);

  return (
    <div className={`${classes.gpa__dark_mode_btn} ${darkMode ? classes.gpa__dark_mode : ''}`}>
      <IOSSwitch sx={{ m: 1 }} checked={darkMode} onChange={toggleDarkModeHandler} />
    </div>
  );
};

export default DarkModeToggle;

import React from 'react';
import { useSelector } from 'react-redux';
import { Col } from 'react-bootstrap';
import classes from './FooterSection.module.css';

const FooterSection = (props) => {
  const darkMode = useSelector((state) => state.theme.darkMode);

  const { sectionTitle, sectionContent, children } = props;
  return (
    <Col lg={3} md={6} sm={6} className={classes.gpa__default_footer_section_col_wrapper}>
      <div
        className={`${classes.gpa__default_footer_section_title_wrapper} ${
          darkMode ? classes.gpa__dark_mode : ''
        }`}
      >
        <h5>{sectionTitle}</h5>
        {sectionContent}
        {children}
      </div>
    </Col>
  );
};
export default FooterSection;

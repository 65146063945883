import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// ===REDUX STORE IMPORTS===
import { useDispatch, useSelector } from 'react-redux';

// ===MUI IMPORTS===
import {
  Button,
  Paper,
  TextField,
  IconButton,
  FilledInput,
  InputLabel,
  InputAdornment,
  FormControl,
  Tooltip,
  Alert,
} from '@mui/material';
import { isLocalhost } from 'utils/constants';
import Spinner from '@mui/material/CircularProgress';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

//= ==BOOTSTRAP IMPORTS===
import { Col, Container, Row, Form } from 'react-bootstrap';

//= ==COMPONENT IMPORTS===
import { CURRENT_APP_VERSION } from 'constants';
import { toggleMode } from 'store/Slices/themeSlice';
import { GPA_APIS } from 'adapters';
import { showAlert } from '../../../Common';
import SEO from '../../../../containers/seo/SEO';
import styles from '../UserRegistration.module.css';
import classes from './LoginForm.module.css';
import { authenticationSuccess } from '../../../../store/Slices/authSlice';
import { registerLoginRecord } from '../../../../utils';
import { SaveTokenInLocalStorage } from '../../../../store/Actions/AuthActions';

const LoginForm = (props) => {
  const darkMode = useSelector((state) => state.theme.darkMode);
  const [isLoading, setIsLoading] = useState(false);
  const message = useSelector((state) => state.auth.message);
  const dispatch = useDispatch();

  const { isSuperAdmin } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [error, setError] = useState('');
  const [values, setValues] = useState({
    password: '',
    email: '',
    showPassword: false,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: event.target.value });

    if (name === 'email' && value.email !== 'undefined') {
      setError('');
      const pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
      );
      if (!pattern.test(value)) {
        setError('Please enter valid email address.');
      }
    }

    if (name === 'password') {
      setError('');
      if (value.length < 6) {
        setError('Password must be atleast 6 characters');
      }
    }
    setError('');
  };

  const LoginHandler = async (e) => {
    e.preventDefault();
    if (window.navigator.onLine === false) {
      showAlert({
        variant: 'error',
        message: 'No internet connection, Please turn data or wifi on and retry again',
      });
      return;
    }

    if (values.email.length < 5) {
      setError('A valid email is required to login');
      return;
    }
    if (values.password.length < 6) {
      setError('Password must be atleast 6 characters');
      return;
    }
    try {
      setIsLoading(true);
      setError('');
      const data = {
        email: values.email,
        password: values.password,
      };
      const res = await GPA_APIS.auth.login(data, isSuperAdmin);
      if (res.status === 200) {
        const isAdmin = res?.data?.user?.roles.includes('admin');
        if (!isAdmin && window.location.hostname !== 'gpaelevator.com' && !isLocalhost) {
          showAlert({
            variant: 'error',
            message:
              'Please use the main website to access all functionality, Redirecting to gpaelevator.com now...',
          });
          window.location.href = 'https://gpaelevator.com';
          setIsLoading(false);
          return;
        }

        dispatch(
          authenticationSuccess({
            data: res?.data,
            user: res?.data?.user,
            token: res?.data?.token,
          }),
        );
        SaveTokenInLocalStorage(dispatch, res?.data);
        dispatch(toggleMode(res?.data?.settings?.theme === 'dark'));

        // ====SEND RECORD TO REGISTER
        registerLoginRecord(res?.data?.user);
        const user_version_major = CURRENT_APP_VERSION.split('.')[0];
        const user_version_minor = CURRENT_APP_VERSION.split('.')[1];
        const user_version_patch = CURRENT_APP_VERSION.split('.')[2];
        const current_version_major = res?.data?.currentVersion.split('.')[0];
        const current_version_minor = res?.data?.currentVersion.split('.')[1];
        const current_version_patch = res?.data?.currentVersion.split('.')[2];
        if (
          // eslint-disable-line
          current_version_major > user_version_major ||
          current_version_minor > user_version_minor ||
          current_version_patch > user_version_patch
        ) {
          showAlert({
            variant: 'warning',
            message: `You are using an older version of the app, Please update to the latest version to access all the features. Your current version is v${CURRENT_APP_VERSION} and the latest version is v${res?.data?.currentVersion}`,
          });
        } else {
          showAlert({ variant: 'success', message: 'Logged in successfully' });
        }
      }
      setIsLoading(false);
      setValues({ password: '', email: '' });
    } catch (err) {
      setIsLoading(false);
      if (err?.response?.status === 400) {
        setError('Invalid credentials, Please try again');
        showAlert({ variant: 'error', message: 'Invalid credentials, Please try again' });
        return;
      }
      if (err?.response?.status === 403) {
        setError('Please verify your account first');
        showAlert({ variant: 'error', message: 'Please verify your account first' });
        return;
      }
      setError(
        'Failed to login, Please verify your account first or double check your credentials if account is already verified',
      );
    }
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Container fluid>
      <SEO
        title='Login'
        description='Login to start your adventure!, Gpa elevator is an online platform that has over 10,000+ reading materials like past papers, lecture notes, questions banks and many more, it eases the lives of university students as well as elevating their GPAs...'
      />
      <section className={styles.gpa__registration_section}>
        <h1
          className={`${styles.gpa__membership_section_title} ${
            darkMode ? styles.gpa__dark_mode : ''
          }`}
        >
          Sign In
        </h1>
        <Paper
          className={`${styles.gpa__register_wrapper} ${classes.gpa__register_wrapper_loggin}`}
        >
          <div className={styles.gpa__register_wrapper_inner}>
            {(message || error) && (
              <div className='d-flex justify-content-center mb-3'>
                <Alert severity='error'>{message || error}</Alert>
              </div>
            )}

            <Form onSubmit={LoginHandler}>
              <TextField
                fullWidth
                label='Email'
                name='email'
                value={values.email}
                variant='filled'
                type='email'
                onChange={handleChange}
                className={styles.gpa__form_input_field}
              />
              <FormControl className={styles.gpa__form_input_field} variant='filled' fullWidth>
                <InputLabel htmlFor='password'>Password</InputLabel>
                <FilledInput
                  type={values.showPassword ? 'text' : 'password'}
                  value={values.password}
                  name='password'
                  onChange={handleChange}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>

              <Row>
                <Col
                  md={6}
                  xs={12}
                  className={`${classes.gpa__password_reset_link_wrapper} ${
                    darkMode ? classes.gpa__dark_mode : ''
                  }`}
                >
                  <Link to='/password-reset'> Forgot Password? </Link>
                </Col>
                <Col
                  md={6}
                  xs={12}
                  className={`${classes.gpa__password_reset_link_wrapper} ${
                    darkMode ? classes.gpa__dark_mode : ''
                  }`}
                >
                  <Link to='/account-verification-request'> Verify your account </Link>
                </Col>
                <Col xs={{ span: 8, offset: 2 }}>
                  <Tooltip
                    placement='top'
                    arrow
                    title={
                      error ||
                      (values.password && values.password.length < 6) ||
                      (values.email && values.email.length < 3)
                        ? 'Enter correct info to login'
                        : 'Login'
                    }
                  >
                    <span>
                      <Button
                        disabled={isLoading}
                        variant='contained'
                        color='primary'
                        type='submit'
                        className={`${styles.gpa__register_submit_button} ${
                          darkMode ? styles.gpa__dark_mode : ''
                        }`}
                      >
                        {isLoading ? 'Logging you In...' : 'Log In'}
                        {isLoading ? (
                          <Spinner thickness={2} size={20} style={{ marginLeft: 5 }} />
                        ) : null}
                      </Button>
                    </span>
                  </Tooltip>
                </Col>
              </Row>
            </Form>
          </div>
          <div
            className={`${styles.gpa__register_form_footer} ${
              darkMode ? styles.gpa__dark_mode : ''
            }`}
          >
            New to gpa elevator?
            <span style={{ marginLeft: 5 }}>
              <Link to='/register'>Register</Link>
            </span>
          </div>
        </Paper>
      </section>
    </Container>
  );
};

export default LoginForm;

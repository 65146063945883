import { GPA_APIS } from 'adapters';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

const useStudentsStatus = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    const getStudentsStatus = async () => {
      if (!isLoggedIn) {
        return;
      }
      try {
        const res = await GPA_APIS.auth.get.getStudentStatus();
        setIsVerified(res?.data?.data?.isVerified);
      } catch (error) {
        setIsVerified(false);
      }
    };
    getStudentsStatus();
  }, [isLoggedIn]);

  return isVerified;
};

export default useStudentsStatus;

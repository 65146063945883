import React from "react";
import { useNavigate } from "react-router-dom";

//= ==REDUX STORE IMPORTS===
import { useDispatch, useSelector } from "react-redux";

//= ==MUI IMPORTS===
import { Button } from "@mui/material";
import LogoutIcon from "@mui/icons-material/ExitToApp";

//= ==COMPONENT IMPORTS===
import { toast } from "react-toastify";
import classes from "./LogoutButton.module.css";
import { logout } from "../../../store/Slices/authSlice";

const LogOutButton = (props) => {
  const DarkMode = useSelector((state) => state.theme.darkMode);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const LogoutHandler = () => {
    dispatch(logout());
    toast.success("You have been logged out");
    navigate("/login");
  };
  return (
    <Button
      variant="contained"
      color="primary"
      {...props}
      onClick={LogoutHandler}
      className={`${classes.gpa__logout_button} ${
        DarkMode ? classes.gpa__dark_mode : ""
      }`}
    >
      Logout
      <LogoutIcon
        style={{ marginLeft: ".5rem" }}
        fontSize="small"
        className={classes.gpa__logout_button_icon}
      />
    </Button>
  );
};
export default LogOutButton;

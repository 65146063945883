/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  documentList: [],
  CUDocuments: [],
  UserDocuments: [],
  totalDocuments: 0,
  document: {},
  isLoading: false,
  status: '',
  message: '',
};
const documentSlice = createSlice({
  name: 'documentList',
  initialState,
  reducers: {
    setDocumentsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    fetchDocumentsSuccess: (state, { payload }) => {
      state.documentList = payload?.files;
      state.totalDocuments = payload?.results;
    },
    fetchCUDocumentsSuccess: (state, { payload }) => {
      state.CUDocuments = payload.files;
      state.status = payload.status;
    },

    fetchUserDocumentsPending: (state) => {
      state.isLoading = true;
    },
    fetchUserDocumentsSuccess: (state, { payload }) => {
      state.UserDocuments = payload.documents;
      state.isLoading = false;
      state.status = payload.status;
    },
    fetchUserDocumentsFail: (state, { payload }) => {
      state.status = payload.status;
      state.isLoading = false;
      state.message = payload.message;
    },
    getCurrentDocument: (state, { payload }) => {
      state.document = payload;
    },
  },
});

const { reducer, actions } = documentSlice;

export const {
  fetchDocumentsSuccess,
  fetchCUDocumentsSuccess,
  fetchUserDocumentsPending,
  fetchUserDocumentsSuccess,
  fetchUserDocumentsFail,
  setDocumentsLoading,
  getCurrentDocument,
} = actions;
export default reducer;

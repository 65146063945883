import React from 'react';
import { NavLink } from 'react-router-dom';
//= ==REDUX IMPORTS===
import { useSelector } from 'react-redux';

//= ==COMPONENT IMPORTS===
import classes from './LoginButton.module.css';
import styles from '../Membership.module.css';

const LoginButton = (props) => {
  const DarkMode = useSelector((state) => state.theme.darkMode);
  const { DrawerClose } = props;

  return (
    <NavLink
      className={({ isActive }) =>
        `${styles.gpa__membership} ${DarkMode ? classes.gpa__dark_mode : ''}` +
        ` ${classes.gpa__login}${isActive ? ` ${classes.gpa__active_link}` : ''}`
      }
      to='/login'
      onClick={DrawerClose}
    >
      Login
    </NavLink>
  );
};

export default LoginButton;

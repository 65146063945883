import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

//= =COMPONENT IMPORTS===
import classes from './GpaLogo.module.css';

const GpaLogo = () => {
  const isAuthenticated = useSelector((state) => state.auth.isLoggedIn);
  // const darkMode = useSelector((state) => state.theme.darkMode);

  return (
    <Link to={isAuthenticated ? '/dashboard/user' : '/'}>
      <img
        className={classes.gpa__logo}
        src='/assets/images/gpa__logo_light.png'
        // src={
        //   darkMode
        //     ? '/assets/images/gpaelevator-logo-dark-n.png'
        //     : '/assets/images/gpaelevator-logo-light.png'
        // }
        alt='GPA ELEVATOR'
      />
    </Link>
  );
};
export default GpaLogo;

import React from "react";
import NavigationItem from "../FooterMenu/NavigationItem";

const QuickLinkMenu = () => (
    <>
      <NavigationItem itemLink="/" itemTitle="Campus News" />
      <NavigationItem itemLink="/" itemTitle="Internship Corner" />
      <NavigationItem itemLink="/" itemTitle="Time Tables" />
    </>
  );
export default QuickLinkMenu;

import React from 'react';
import { useSelector } from 'react-redux';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import navStyles from '../../FooterMenu/FooterNavigation.module.css';
import styles from './FooterAccordion.module.css';
import clx from '../MobileFooter.module.css';
import './Footer.css';

const FooterAccordion = (props) => {
  const DarkMode = useSelector((state) => state.theme.darkMode);
  const { menuTitle, navMenu } = props;

  return (
    <Accordion disableGutters square={false} className={styles.gpa__footer_section_accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1c-content'
        id='panel1c-header'
        style={{ minHeight: '48px', margin: '0px' }}
      >
        <Typography className={styles.heading} variant='h5'>
          {menuTitle}
        </Typography>
      </AccordionSummary>

      <AccordionDetails className={styles.gpa__mobile_footer_section_collapse_wrapper}>
        <nav
          className={`${navStyles.gpa__footer_navigation} ${DarkMode ? clx.gpa__dark_mode : ''} ${
            clx.gpa__mobile_footer_navigation
          } `}
        >
          <ul>{navMenu}</ul>
        </nav>
      </AccordionDetails>
    </Accordion>
  );
};
export default FooterAccordion;

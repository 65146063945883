import React from 'react';
import { GPA_APIS } from 'adapters';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMode } from 'store/Slices/themeSlice';
import DarkModeToggle from 'components/UI/DarkModeToggle/DarkModeToggle';
import { Link } from 'react-router-dom';
import { UserMenu } from 'components/Common';
import Wrapper from '../../../containers/hoc/wrapper';
import classes from './SideDrawerContent.module.css';
import SideMenu from './SideMenu/SideMenu';
import MobileDrawerFooter from '../MobileDrawerFooter/MobileDrawerFooter';

export const SideDrawerLogo = (props) => {
  const darkMode = useSelector((state) => state.theme.darkMode);
  const { DrawerClose } = props;
  const dispatch = useDispatch();

  const toggleDarkModeHandler = async () => {
    dispatch(toggleMode(!darkMode));
    const data = {
      theme: darkMode ? 'light' : 'dark',
    };
    await GPA_APIS.auth.updateUserSettings(data);
  };
  return (
    <>
      <div
        className={`${classes.gpa__sidedrawer_header_wrapper} ${
          darkMode ? classes.gpa__dark_mode : ''
        }`}
      >
        <div className={classes.gpa__sidedrawer_header_logo}>
          <Link to='/' onClick={DrawerClose}>
            <img
              className={classes.gpa__logo}
              src='/assets/images/gpa__logo_light.png'
              alt='GPA ELEVATOR'
            />
          </Link>
        </div>
      </div>
      <div
        className={`${classes.gpa__sidedrawer_header_theme_mode_switch_wrapper} ${
          darkMode ? classes.gpa__dark_mode : ''
        } inset-2`}
      >
        <span onClick={toggleDarkModeHandler}>{darkMode ? ' Dark Mode On' : ' Dark Mode Off'}</span>
        <DarkModeToggle />
      </div>
    </>
  );
};

const SideDrawerContent = (props) => {
  const isAuthenticated = useSelector((state) => state.auth.isLoggedIn);
  const { DrawerClose } = props;

  return (
    <Wrapper>
      <SideDrawerLogo DrawerClose={DrawerClose} />
      {isAuthenticated ? (
        <div className='w-full flex flex-col pt-1  pb-10 overflow-y-auto'>
          <UserMenu onClose={DrawerClose} />
        </div>
      ) : (
        <SideMenu DrawerClose={DrawerClose} />
      )}
      <div
        className={`${classes.gpa__mobile_drawer_footer} ${
          !isAuthenticated ? 'absolute bottom-0 left-0 right-0' : ''
        }`}
      >
        <MobileDrawerFooter DrawerClose={DrawerClose} />
      </div>
    </Wrapper>
  );
};

export default SideDrawerContent;

import axios from 'axios';
import { FIREBASE_NOTIFICATIONS_SERVER_KEY } from 'constants';

/**
 * @name notificationRequests
 * @description Function used to make request to the auth endpoints
 * @param requestor
 * @returns {object} notificationRequests
 */
export const notificationRequests = () => ({
  /**
   * @name sendNotifications
   * @description Function used to make request to sendNotifications user
   * @param {object} data
   * @returns {Promise<any>} response
   */
  sendNotifications: async (data) => {
    const response = await axios.post(
      'https://fcm.googleapis.com/fcm/send',
      {
        notification: {
          title: data?.title,
          body: data?.message,
          click_action: data?.click_action,
          icon: 'https://beta.gpaelevator.com/gpa-icon-curved.png',
        },
        registration_ids: data?.users,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Key=${FIREBASE_NOTIFICATIONS_SERVER_KEY}`,
        },
      },
    );
    return response;
  },
});

import { toast } from 'react-toastify';
import { RECORD_TYPE, SHOW_PROMPT_TIME } from '../../utils/constants';
import GPA_REGISTER from '../../utils/GpaRegister';
import {
  accountVerificationRoute,
  axios,
  extendSessionRoute,
  forgotPasswordRoute,
  loginRoute,
  passwordResetRoute,
  requestAccountVerificationRoute,
  showError,
  signupRoute,
} from '../../utils/lib';
import { baseUrl } from '../../constants';
import {
  authenticationSuccess,
  updateProfilePending,
  updateProfileSuccess,
  updateProfileFail,
  logout,
  autoAuthenticationSuccess,
  verificationSuccess,
  updateUserRolePending,
  updateUserRoleFail,
  updateUserRoleSuccess,
  setAuthenticationLoading,
} from '../Slices/authSlice';
import { clearNotifications } from '../Slices/chatSlice';
import { requestVerificationSuccess, setPasswordLoading } from '../Slices/passwordSlice';
import { setShowTimeUpPrompt } from '../Slices/uiSlice';
import { setRegistrationLoading } from '../Slices/userRegistrationSlice';

export const logOutTimer = (dispatch, timer) => {
  const timeOut = timer - SHOW_PROMPT_TIME;
  setTimeout(() => {
    dispatch(setShowTimeUpPrompt(true));
  }, timeOut);

  const timerId = setTimeout(() => {
    dispatch(setShowTimeUpPrompt(false));
    dispatch(logout());
  }, timer);

  const timerIdOld = JSON.parse(localStorage.getItem('gptids')) || [];
  localStorage.setItem('gptids', JSON.stringify([timerId, ...timerIdOld]));
};

export const SaveTokenInLocalStorage = (dispatch, userDetails) => {
  logOutTimer(dispatch, userDetails.expiresIn);
  const AuthTokenDetails = {
    token: userDetails.token,
    expiresIn: userDetails.expiresIn,
    expirationTime: userDetails.expirationTime,
  };
  localStorage.setItem('AuthToken', JSON.stringify(AuthTokenDetails));
  localStorage.setItem('CurrentUser', JSON.stringify(userDetails.user));
};

/**
 * @name extendSession
 * @description This function is used to verify a user's account and extend their session by 2hrs
 * @param {object} data
 * @returns {object} dispatch
 */
export const extendSession = (data) => async (dispatch) => {
  dispatch(setAuthenticationLoading(true));
  try {
    const { url } = extendSessionRoute();
    const res = await axios.post(url, data);
    dispatch(
      authenticationSuccess({
        user: res?.data?.user,
        token: res?.data?.token,
      }),
    );
    toast.success('Session extended successfully');
    SaveTokenInLocalStorage(dispatch, res?.data);
    dispatch(setAuthenticationLoading(false));
    dispatch(setShowTimeUpPrompt(false));
  } catch (err) {
    toast.error('Failed to extend your session!');
    dispatch(setShowTimeUpPrompt(false));
    dispatch(logout());
  }
};

export const login = (data) => async (dispatch) => {
  dispatch(setAuthenticationLoading(true));
  try {
    const { url } = loginRoute();
    const res = await axios.post(url, data);
    if (res?.status === 200) {
      dispatch(
        authenticationSuccess({
          data: res?.data,
          user: res?.data?.user,
          token: res?.data?.token,
        }),
      );
      toast.success('Login successful');
      SaveTokenInLocalStorage(dispatch, res?.data);

      // ====GET USER DETAILS====
      const AccessDate = new Date().toLocaleDateString('en-GB');
      const AccessTime = new Date().toLocaleTimeString('en-GB', { hour12: true });
      const UserDetails = [
        `${res?.data.user.first_name} ${res?.data.user.last_name}`,
        res?.data.user.email,
        res?.data.user.role,
        res?.data.user.university.name.replace(/-/g, ' '),
        res?.data.user.course.name.replace(/-/g, ' '),
        AccessDate,
        AccessTime.split(' ')[0],
        AccessTime.split(' ')[1].toUpperCase(),
      ];

      // ====WRITE THE LOGIN RECORD TO THE REGISTER====//
      GPA_REGISTER(RECORD_TYPE.LOGIN, UserDetails);
      dispatch(setAuthenticationLoading(false));
    }
  } catch (err) {
    dispatch(setAuthenticationLoading(false));
    if (err?.response?.status === 400) {
      toast.error('Invalid email/password!');
      return;
    }
    if (err?.response?.status === 403) {
      toast.error('Please verify your account and try again');
      return;
    }
    toast.error(showError(err));
  }
};

export const UpdateRole = (role, user_id, AuthToken) => async (dispatch) => {
  dispatch(updateUserRolePending());
  const response = await fetch(`${baseUrl}/users/updateUserRole`, {
    method: 'POST',
    body: JSON.stringify({
      role,
      user_id,
    }),
    headers: new Headers({
      'Content-type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }),
  });
  if (!response.ok) {
    const error = await response.json();
    let message = '';
    if (error.message === 'user not found') {
      message = 'User not found, Please double check your credentials and try again';
    } else if (error.error.statusCode === 500) {
      message = 'Role verification failed';
    } else {
      message = 'Failed to update user role, Please check your connection and try again';
    }
    dispatch(updateUserRoleFail(message));
  }
  const data = await response.json();
  dispatch(updateUserRoleSuccess(data));
};

export const signup = (data, navigate) => async (dispatch) => {
  dispatch(setRegistrationLoading(true));
  try {
    const { url } = signupRoute();

    const res = await axios.post(url, data);
    dispatch(setRegistrationLoading(false));
    if (res.status === 200) {
      toast.success('Account created Successfully, Check your email for a link to verify it');
      navigate('/login');
    }
  } catch (err) {
    toast.error(showError(err));
    dispatch(setRegistrationLoading(false));
  }
};

export const updateProfile =
  (first_name, last_name, phone_number, AuthToken) => async (dispatch) => {
    dispatch(updateProfilePending());
    const response = await fetch(`${baseUrl}/users/updateMe`, {
      method: 'PATCH',
      body: JSON.stringify({
        first_name,
        last_name,
        phone_number,
      }),
      headers: new Headers({
        'Content-type': 'application/json',
        Authorization: `Bearer ${AuthToken}`,
      }),
    });

    if (!response.ok) {
      const error = await response.json();
      dispatch(updateProfileFail(error));
    }
    const UserData = await response.json();
    dispatch(updateProfileSuccess(UserData.data.user));
    localStorage.setItem('CurrentUser', JSON.stringify(UserData.data.user));
  };

export const forgotPassword = (email) => async (dispatch) => {
  dispatch(setPasswordLoading(true));
  try {
    const { url } = forgotPasswordRoute();
    const res = await axios.post(url, { email });
    if (res?.status === 200) {
      toast.success('Please check your email for a reset link');
    }
    dispatch(setPasswordLoading(false));
  } catch (err) {
    toast.error(showError(err));
    dispatch(setPasswordLoading(false));
  }
};

export const RequestAccountVerification = (email) => async (dispatch) => {
  dispatch(setPasswordLoading(true));
  try {
    const { url } = requestAccountVerificationRoute();

    const res = await axios.post(url, { email });
    dispatch(setPasswordLoading(false));
    dispatch(requestVerificationSuccess(res?.data));
    toast.success('Please check your email to verify account');
  } catch (err) {
    dispatch(setPasswordLoading(false));
    toast.error(showError(err));
  }
};

export const VerifyAccount = (verificationToken) => async (dispatch) => {
  dispatch(setAuthenticationLoading(true));
  try {
    const { url } = accountVerificationRoute(verificationToken);
    const res = await axios.patch(url);
    if (res.status === 200) {
      toast.success('Account Verified Successfully');
      dispatch(
        verificationSuccess({
          data: res?.data,
          user: res?.data?.user,
          token: res?.data?.token,
        }),
      );
      SaveTokenInLocalStorage(dispatch, res?.data);
    }
    dispatch(setAuthenticationLoading(false));
  } catch (err) {
    dispatch(setAuthenticationLoading(false));
    toast.error('Failed to verify account');
  }
};

export const passwordReset = (password, pwdResetToken, navigate) => async (dispatch) => {
  dispatch(setPasswordLoading(true));
  try {
    const { url } = passwordResetRoute(pwdResetToken);
    const res = await axios.patch(url, { password });
    if (res.status === 200) {
      toast.success('Password updated successfully');
      navigate('/login');
    }
    dispatch(setPasswordLoading(false));
  } catch (err) {
    dispatch(setPasswordLoading(false));
    toast.error('Failed to update password, try again');
  }
};

export const AutoAuthenticate = (dispatch) => {
  const AuthToken = localStorage.getItem('AuthToken');
  const CurrentUser = localStorage.getItem('CurrentUser');
  let UserToken = '';
  if (!AuthToken) {
    dispatch(logout());
    return;
  }
  UserToken = JSON.parse(AuthToken);
  const expireDate = new Date(UserToken.expirationTime);
  const todaysDate = new Date();
  if (todaysDate > expireDate) {
    dispatch(logout());
    return;
  }
  const data = {
    token: UserToken.token,
    user: JSON.parse(CurrentUser),
  };
  dispatch(autoAuthenticationSuccess(data));

  const userNotifications = localStorage.getItem('userNotifications');
  if (!userNotifications) {
    dispatch(clearNotifications([]));
  } else {
    const notifications = JSON.parse(userNotifications);
    dispatch(clearNotifications(notifications));
  }

  const timer = expireDate.getTime() - todaysDate.getTime();
  logOutTimer(dispatch, timer);
};

import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import classes from './DashBoardMenuItem.module.css';

const DashBoardMenuItem = ({
  menuItemLink,
  menuItemTitle,
  menuItemIcon,
  menuCount,
  beta,
  isNew,
  onClose,
}) => {
  const darkMode = useSelector((state) => state.theme.darkMode);
  return (
    <div
      className={`${classes.gpa__dashboard_menu_item_link_wrapper} ${
        darkMode ? classes.gpa__dark_mode : ''
      }`}
      onClick={onClose}
    >
      <NavLink
        className={({ isActive }) =>
          `${classes.gpa__dashboard_menu_item_wrapper} ${darkMode ? classes.gpa__dark_mode : ''}` +
          ` ${menuItemTitle === 'Dashboard' && classes.gpa__dashboard_menu_item_dash}${
            isActive ? ` ${classes.gpa__selected_menu_item}` : ''
          }`
        }
        to={`/dashboard/${menuItemLink}`}
        end
      >
        <Tooltip placement='top' title={menuItemTitle}>
          <div className={classes.gpa__dashboard_menu_item_icon_wrapper}>{menuItemIcon}</div>
        </Tooltip>
        <div
          className={`${classes.gpa__dashboard_menu_item_title_wrapper} ${
            beta && !isNew ? classes.gpa__beta : ''
          } ${isNew && !beta ? classes.gpa__new : ''} ${darkMode ? classes.gpa__dark_mode : ''}`}
        >
          {menuItemTitle}
        </div>
        {menuCount > 0 && (
          <span
            className={`${classes.gpa__dashboard_menu_item_count} ${
              darkMode ? classes.gpa__dark_mode : ''
            }`}
          >
            {menuCount}
          </span>
        )}
      </NavLink>
    </div>
  );
};

export default DashBoardMenuItem;

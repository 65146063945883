import React, { useState } from 'react';

// ===MUI IMPORTS===
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, Paper } from '@mui/material';

// ===BOOTSTRAP IMPORTS===
import { Container, Col, Row } from 'react-bootstrap';

// ===REDUX STORE IMPORTS===
import { useUniversities } from 'hooks';
import { useSelector } from 'react-redux';

// ===COMPONENT IMPORTS===
import UniversityCard from './UniversityCard/UniversityCard';
import UniversityCardSkelton from './UniversityCard/UniversityCardSkelton';
import Wrapper from '../../containers/hoc/wrapper';
import classes from './Universities.module.css';
import Spinner from '../UI/Spinner/Spinner';
import Heading from '../UI/SectionHeading/Heading';

const Universities = () => {
  const [refresh, setRefresh] = useState(null);
  const darkMode = useSelector((state) => state.theme.darkMode);
  const { universities, loading } = useUniversities(refresh);

  let RenderedList = <Spinner />;
  let uni_name = '';
  if (universities) {
    RenderedList = universities.map((university) => {
      uni_name = university.name.replace(/-/g, ' ');
      return (
        <Col
          lg={3}
          md={3}
          sm={6}
          xs={12}
          className={classes.gpa__university_card_wrapper}
          key={university.id}
        >
          <UniversityCard
            universityId={university.id}
            universityLink={university.name}
            universityCode={university.university_code}
            universityName={uni_name}
            universityLogo={university.logo}
          />
        </Col>
      );
    });
  }

  const getDisplayedComponent = () => {
    if (loading) {
      return [...Array(12).keys()].map((index) => (
        <Col
          lg={3}
          md={3}
          sm={6}
          xs={12}
          className={classes.gpa__university_card_wrapper}
          key={index}
        >
          <UniversityCardSkelton />
        </Col>
      ));
    }
    if (RenderedList.length > 0) {
      return RenderedList;
    }

    return (
      <Paper className={classes.gpa__failed_to_fetch_universities_error_wrapper}>
        Failed to fetch universities!
        <Button
          onClick={() => setRefresh(Math.random())}
          endIcon={<RefreshIcon />}
          variant='outlined'
        >
          Reload
        </Button>
      </Paper>
    );
  };

  return (
    <Wrapper>
      <Heading sectionHeading='Universities in Uganda' />
      <Container
        fluid
        className={`${classes.gpa__universities_wrapper} ${darkMode ? classes.gpa__dark_mode : ''}`}
      >
        <Row className={classes.gpa__universities_wrapper_row}>{getDisplayedComponent()}</Row>
      </Container>
    </Wrapper>
  );
};
export default Universities;

/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  status: '',
  message: '',
};

export const passwordSlice = createSlice({
  initialState,
  name: 'passwordSlice',
  reducers: {
    setPasswordLoading(state, { payload }) {
      state.isLoading = payload;
    },
    requestVerificationPending(state) {
      state.isLoading = true;
    },
    requestVerificationSuccess(state, { payload }) {
      state.isLoading = false;
      state.message = payload.message;
      state.status = payload.status;
    },
    requestVerificationFail(state, { payload }) {
      state.isLoading = false;
      state.message = payload.message;
      state.status = payload.status;
    },
  },
});
const { reducer, actions } = passwordSlice;

export const {
  requestVerificationPending,
  requestVerificationSuccess,
  requestVerificationFail,
  setPasswordLoading,
} = actions;

export default reducer;

import { CURRENT_APP_VERSION } from 'constants';
import {
  CLIENT_EMAIL,
  DOCUMENT_ACCESS_SHEET_ID,
  DOWNLOAD_SHEET_ID,
  LOGIN_SHEET_ID,
  PAYMENT_ATTEMPT_SHEET_ID,
  PRIVATE_KEY,
  RECORD_TYPE,
  SHEET_URL,
  UPLOADED_DOCUMENTS_SHEET_ID,
  VOLUNTEERS_SHEET_ID,
} from './constants';

const getSheetId = (recordType) => {
  switch (recordType) {
    case RECORD_TYPE.DOWNLOAD:
      return DOWNLOAD_SHEET_ID;
    case RECORD_TYPE.DOCUMENT_ACCESS:
      return DOCUMENT_ACCESS_SHEET_ID;
    case RECORD_TYPE.DOCUMENT_UPLOAD:
      return UPLOADED_DOCUMENTS_SHEET_ID;
    case RECORD_TYPE.VOLUNTEER:
      return VOLUNTEERS_SHEET_ID;
    case RECORD_TYPE.PAYMENT_ATTEMPT:
      return PAYMENT_ATTEMPT_SHEET_ID;
    default:
      return LOGIN_SHEET_ID;
  }
};

const GPA_REGISTER = async (recordType, rowData) => {
  if (process.env.NODE_ENV === 'production' || recordType === RECORD_TYPE.DOCUMENT_UPLOAD) {
    const sheetId = getSheetId(recordType);
    const data = {
      sheetId: +sheetId,
      data: [...rowData, CURRENT_APP_VERSION],
      client_email: CLIENT_EMAIL,
      private_key: PRIVATE_KEY,
    };

    try {
      await fetch(SHEET_URL, {
        mode: 'no-cors',
        method: 'POST',
        headers: new Headers({
          'Content-type': 'application/json',
        }),
        body: JSON.stringify(data),
      });
    } catch (e) {
      // ====CODE IF ERROR
    }
  }
};

export default GPA_REGISTER;

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import './styles.css';

const NewMemberPrompt = () => {
  const [showPrompt, setShowPrompt] = useState(false);
  const [timerId, setTimerId] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!showPrompt) {
      const promptTimerId = setTimeout(() => {
        setShowPrompt(true);
      }, 3000);
      setTimerId(promptTimerId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    location.pathname === '/login' ||
    location.pathname === '/register' ||
    location.pathname === '/password-reset' ||
    location.pathname === '/account-verification-request' ||
    location.pathname === '/change-password' ||
    location.pathname.includes('verify-account') ||
    location.pathname.includes('reset-password')
  ) {
    clearTimeout(timerId);
    return null;
  }

  const handleMemberShipPrompt = (type) => {
    if (type === 'login') {
      navigate('/login');
    } else {
      navigate('/register');
    }
    clearTimeout(timerId);
    setShowPrompt(false);
  };

  return (
    <div
      className='flex flex-col justify-center items-center fixed bottom-0 right-0 left-0 md:hidden'
      style={{
        zIndex: 9990,
        visibility: showPrompt ? 'visible' : 'hidden',
        opacity: showPrompt ? 1 : 0,
      }}
    >
      <div className={`gpa__notification_prompt_membership ${showPrompt ? 'Slide-in' : ''}`}>
        <div className='gpa__prompt_wrapper_membership'>
          <div className='w-full'>
            <div className='flex justify-end'>
              <div
                className='text-errorText bg-errorBg rounded-full p-2'
                onClick={() => setShowPrompt(false)}
              >
                <svg
                  height='20'
                  viewBox='0 0 1000 1000'
                  width='20'
                  xmlns='http://www.w3.org/2000/svg'
                  className='cursor-pointer'
                  fill='currentColor'
                >
                  <path d='M159 204l55-54 659 659-55 55-659-660m709 5L205 877l-55-59 664-664' />
                </svg>
              </div>
            </div>
            <div className='w-full p-2'>
              <p>
                Explore exciting features of GPA ELEVATOR like organized past papers, filtered
                youtube videos, summaries and custom content from your favorite lecturers with a
                free account.
              </p>
            </div>

            <div className='w-full flex justify-center gap-2 p-3'>
              <Button
                variant='outlined'
                color='secondary'
                className='w-1/2'
                onClick={() => handleMemberShipPrompt('signup')}
              >
                Sign Up
              </Button>

              <Button
                variant='contained'
                type='button'
                className='w-1/2'
                onClick={() => handleMemberShipPrompt('login')}
              >
                Login
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewMemberPrompt;

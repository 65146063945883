import React from 'react';
import { useSelector } from 'react-redux';

import FooterAccordion from './CollapsedSection/FooterAccordion';
import AboutUsMenu from '../AboutUsSection/AboutUsMenu';
import classes from './MobileFooter.module.css';
import SocialMedia from '../../SocialMedia/SocialMedia';
import CopyRight from '../CopyRight/CopyRight';
import ContactSection from '../ContactUsSection/ContactSection';
import QuickLinkMenu from '../QuickLinks/QuickLinkMenu';
import HelpCenterMenu from '../HelpCenter/HelpCenterMenu';

const MobileFooter = () => {
  const DarkMode = useSelector((state) => state.theme.darkMode);
  return (
    <>
      <div
        className={`${classes.gpa__mobile_footer_wrapper} ${
          DarkMode ? classes.gpa__dark_mode : ''
        }`}
      >
        <FooterAccordion menuTitle='About Us' navMenu={<AboutUsMenu />} />
        <FooterAccordion menuTitle='Contact Us' navMenu={<ContactSection />} />
        <FooterAccordion menuTitle='Quick Links' navMenu={<QuickLinkMenu />} />
        <FooterAccordion menuTitle='Help Center' navMenu={<HelpCenterMenu />} />
      </div>
      <div
        className={`${classes.gpa__mobile_footer_copyright_section_wrapper} ${
          DarkMode ? classes.gpa__dark_mode : ''
        }`}
      >
        <SocialMedia />
        <CopyRight />
      </div>
    </>
  );
};
export default MobileFooter;

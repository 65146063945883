import { getCoursesByUniversityIdRoute } from '../../routes';

/**
 * Handle all the API calls to handle course apis
 * @name courseRequests
 * @param {(config: RequestConfig)=> any} requestor
 */
export const courseRequests = (requestor) => ({
  /**
   * @name createNewCourse
   * @description create a new course
   * @param {object} data
   * @returns {Promise<any>}
   */
  createNewCourse: async (data) => {
    const url = `/courses/register`;
    const response = await requestor({
      method: 'POST',
      url,
      data,
    });
    return response;
  },

  /**
   * @name editCourseDetails
   * @description edit course details
   * @param {object} data
   * @param {string} courseId
   * @returns {Promise<any>}
   */
  editCourseDetails: async (data, courseId) => {
    const url = `/courses/edit/${courseId}`;
    const response = await requestor({
      method: 'PUT',
      url,
      data,
    });
    return response;
  },

  /**
   * @name get - handle all get requests for estimate
   */
  get: {
    /**
     * Get all courses from the database using the university id
     * @name getCoursesByUniversityId
     * @param {string} universityId
     * @returns {Promise<any>}
     */
    getCoursesByUniversityId: async (universityId) => {
      const { url } = getCoursesByUniversityIdRoute(universityId);
      const response = await requestor({
        method: 'GET',
        url,
      });
      return response;
    },
  },
});

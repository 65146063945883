/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialMode = {
  display: true,
  relatedShow: window.innerWidth > 768,
  recentShow: false,
  showTimeUpPrompt: false,
  showUserMenu: false,
};
const uiSlice = createSlice({
  name: 'uiSlice',
  initialState: initialMode,
  reducers: {
    displayComponentHandler(state) {
      state.display = !state.display;
    },
    showRecentHandler(state) {
      state.recentShow = !state.recentShow;
      state.relatedShow = false;
    },
    showRelatedHandler(state) {
      state.recentShow = false;
      state.relatedShow = !state.relatedShow;
    },
    setShowTimeUpPrompt(state, { payload }) {
      state.showTimeUpPrompt = payload;
    },
    handleMenuPopup(state, { payload }) {
      state.showUserMenu = payload;
    },
  },
});

const { reducer, actions } = uiSlice;

export const {
  displayComponentHandler,
  showRelatedHandler,
  showRecentHandler,
  setShowTimeUpPrompt,
  handleMenuPopup,
} = actions;

export default reducer;

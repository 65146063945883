import { Paper } from '@mui/material';
import React from 'react';
import { Container } from 'react-bootstrap';
import classes from './Heading.module.css';

const Heading = (props) => {
  const { sectionHeading } = props;
  return (
    <Paper elevation={0}>
      <Container fluid className={classes.gpa__section_heading_wrapper}>
        <h3>{sectionHeading}</h3>
      </Container>
    </Paper>
  );
};

export default Heading;

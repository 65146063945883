/**
 * Handle all the API calls to handle course unit apis
 * @name courseUnitRequests
 * @param {(config: RequestConfig)=> any} requestor
 */
export const courseUnitRequests = (requestor) => ({
  /**
   * @name createNewCourseUnit
   * @description create a new course unit
   * @param {object} data
   * @returns {Promise<any>}
   */
  createNewCourseUnit: async (data) => {
    const url = `/courseUnits/register`;
    const response = await requestor({
      method: 'POST',
      url,
      data,
    });
    return response;
  },

  /**
   * @name editCourseUnitDetails
   * @description edit course unit details
   * @param {object} data
   * @param {string} courseUnitId
   * @returns {Promise<any>}
   */
  editCourseUnitDetails: async (data, courseUnitId) => {
    const url = `/courseUnits/edit/${courseUnitId}`;
    const response = await requestor({
      method: 'PUT',
      url,
      data,
    });
    return response;
  },
  /**
   * @name deactivateCourseUnit
   * @description Deactivate a course unit
   * @param {string} courseUnitId
   * @returns {Promise<any>}
   */
  deactivateCourseUnit: async (courseUnitId) => {
    const url = `/courseUnits/deactivateCourseUnit/${courseUnitId}`;
    const response = await requestor({
      method: 'PATCH',
      url,
    });
    return response;
  },

  /**
   * @name get - handle all get requests for course unit
   */
  get: {
    /**
     * Get course unit details using the name
     * @name courseUnitDetailsByUniNameAndCourseName
     * @param {string} universityName
     * @param {string} courseName
     * @param {string} courseUnitName     *
     * @returns {Promise<any>}
     */
    courseUnitDetailsByUniNameAndCourseName: async ({
      universityName,
      courseName,
      courseUnitSlug,
    }) => {
      const url = `/courseUnits?uni_name=${universityName}&course_name=${courseName}&course_unit=${courseUnitSlug}`;
      const response = await requestor({
        method: 'GET',
        url,
      });
      return response;
    },

    /**
     * @name courseUnitDocuments
     * @description Get course unit documents using the course unit id
     * @param {string} courseUnitId
     * @returns {Promise<any>}
     */
    courseUnitDocuments: async (courseUnitId) => {
      const url = `/files/${courseUnitId}`;
      const response = await requestor({
        method: 'GET',
        url,
      });
      return response;
    },

    /**
     * @name getCourseUnitsByCourseId
     * @description Get course units for a course using the course id
     * @param {string} courseId
     * @param {string} year
     * @param {string} semester
     * @returns {Promise<any>}
     */
    getCourseUnitsByCourseId: async (courseId, year, semester) => {
      let url = `/courseUnits/getCourseUnitsByCourseId?courseId=${courseId}`;
      if (year && semester) {
        url = `${url}&year=${year}&semester=${semester}`;
      }
      const response = await requestor({
        method: 'GET',
        url,
      });
      return response;
    },

    /**
     * @name categoryDocuments
     * @description Get course unit documents using the course unit id
     * @param {string} courseUnitId
     * @returns {Promise<any>}
     */
    categoryDocuments: async (courseUnitId, category) => {
      const url = `/files/course-unit-documents?course_unit_id=${courseUnitId}&category=${category}`;
      const response = await requestor({
        method: 'GET',
        url,
      });
      return response;
    },

    /**
     * @description Get course unit users using the id
     * @name courseUnitUsers
     * @param {string} courseUnitId
     * @returns {Promise<any>}
     */
    courseUnitUsers: async (courseUnitId) => {
      const url = `/courseUnits/enrolled/${courseUnitId}`;
      const response = await requestor({
        method: 'GET',
        url,
      });
      return response;
    },

    /**
     * @name enrollToCourseUnit
     * @description Get course unit users using the id
     * @param {string} courseUnitId
     * @returns {Promise<any>}
     */
    enrollToCourseUnit: async (courseUnitId) => {
      const url = `/courseUnits/enrollUser/${courseUnitId}`;
      const response = await requestor({
        method: 'GET',
        url,
      });
      return response;
    },
  },
});

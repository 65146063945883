import { VIDEO_TYPES } from 'constants';
import GPA_REGISTER from './GpaRegister';
import { DOCUMENT_TYPE, RECORD_TYPE } from './constants';

export const registerLoginRecord = (userData) => {
  if (!userData) return;
  const AccessDate = new Date().toLocaleDateString('en-GB');
  const AccessTime = new Date().toLocaleTimeString('en-GB', { hour12: true });
  const UserDetails = [
    `${userData.first_name} ${userData.last_name}`,
    userData.email,
    userData.roles.map((role) => role).join(', '),
    userData.university.name.replace(/-/g, ' '),
    userData.course.name.replace(/-/g, ' '),
    AccessDate,
    AccessTime.split(' ')[0],
    AccessTime.split(' ')[1].toUpperCase(),
  ];

  // ====WRITE THE LOGIN RECORD TO THE REGISTER====//
  GPA_REGISTER(RECORD_TYPE.LOGIN, UserDetails);
};

export const saveBuyPointsTrialRecord = (userData, data) => {
  if (!userData) return;
  const AccessDate = new Date().toLocaleDateString('en-GB');
  const AccessTime = new Date().toLocaleTimeString('en-GB', { hour12: true });
  const UserDetails = [
    data?.user_id,
    `${userData.first_name} ${userData.last_name}`,
    userData.email,
    userData.role,
    userData.university.name.replace(/-/g, ' '),
    userData.course.name.replace(/-/g, ' '),
    data?.amount,
    data?.access_points,
    data?.phone_number,
    AccessDate,
    AccessTime.split(' ')[0],
    AccessTime.split(' ')[1].toUpperCase(),
  ];

  // ====WRITE THE LOGIN RECORD TO THE REGISTER====//
  GPA_REGISTER(RECORD_TYPE.PAYMENT_ATTEMPT, UserDetails);
};

export const saveDocumentAccessRecord = (document, userData) => {
  if (!document || !userData) return;
  const uniName = userData.university.name.replace(/-/g, ' ');
  const courseName = userData.course.name.replace(/-/g, ' ');

  // ====GET CURRENT DATE AND TIME====
  const AccessDate = new Date().toLocaleDateString('en-GB');
  const AccessTime = new Date().toLocaleTimeString('en-GB', { hour12: true });

  //= ===STRUCTURING DOCUMENT ACCESS DETAILS====//
  const newAccessDetails = [
    userData?.email,
    uniName,
    courseName,
    `${document?.course_unit?.course_unit_code?.toUpperCase()}:${document?.course_unit?.name?.replace(
      /-/g,
      ' ',
    )}`,
    document?.file_type === DOCUMENT_TYPE.VIDEO_TYPE
      ? document?.video_title
      : document?.custom_name,
    document?.category,
    AccessDate,
    AccessTime.split(' ')[0],
    AccessTime.split(' ')[1]?.toUpperCase(),
  ];

  // ====WRITE THE ACCESS RECORD TO THE REGISTER====//
  GPA_REGISTER(RECORD_TYPE.DOCUMENT_ACCESS, newAccessDetails);
};

export const saveDocumentDownloadRecord = (document, userData) => {
  if (!document || !userData) return;
  const uniName = userData.university.name.replace(/-/g, ' ');
  const courseName = userData.course.name.replace(/-/g, ' ');

  // ====GET CURRENT DATE AND TIME====
  const AccessDate = new Date().toLocaleDateString('en-GB');
  const AccessTime = new Date().toLocaleTimeString('en-GB', { hour12: true });

  //= ===STRUCTURING DOCUMENT ACCESS DETAILS====//
  const newAccessDetails = [
    userData?.email,
    uniName,
    courseName,
    `${document?.course_unit?.course_unit_code?.toUpperCase()}:${document?.course_unit?.name?.replace(
      /-/g,
      ' ',
    )}`,
    document?.file_type === DOCUMENT_TYPE.VIDEO_TYPE
      ? document?.video_title
      : document?.custom_name,
    document?.category,
    AccessDate,
    AccessTime.split(' ')[0],
    AccessTime.split(' ')[1]?.toUpperCase(),
  ];

  // ====WRITE THE ACCESS RECORD TO THE REGISTER====//
  GPA_REGISTER(RECORD_TYPE.DOWNLOAD, newAccessDetails);
};

export const scrollToElement = (eleId) => {
  const targetElement = document.getElementById(eleId);
  const headerEle = document.getElementById('gpa__back_to_top_anchor');
  const headerHeight = headerEle.offsetHeight;
  const scrollToPosition = targetElement.offsetTop - headerHeight;
  window.scrollTo({ top: scrollToPosition, behavior: 'smooth' });
};

/**
 * @name isUniversityEmail
 * @description Check if the email is from the university
 * @param {string} email
 * @param {string} universityDomain
 * @returns  {boolean} isUniversityEmail
 */
export function isUniversityEmail(email, universityDomain) {
  if (!email || !universityDomain) return false;
  // Extract the domain part from the university domain URL
  const domainMatch = universityDomain.match(/:\/\/(www\.)?(.[^/]+)/);
  if (domainMatch && domainMatch[2]) {
    const sanitizedUniversityDomain = domainMatch[2];

    // Split the email address by "@" to get the domain part
    const emailDomain = email.split('@')[1];

    // Check if the email domain matches the sanitized university domain
    if (
      emailDomain &&
      emailDomain.toLowerCase().endsWith(sanitizedUniversityDomain.toLowerCase())
    ) {
      return true;
    }
  }
  return false;
}

/**
 * @name getFormattedVideoDuration
 * @description Get the formatted video duration
 * @param {string} duration PT1H27M18S
 * @returns {string} formattedVideoDuration in minutes
 */
/* eslint-disable radix */
export const getFormattedVideoDuration = (duration) => {
  if (!duration) return '';
  const hours = duration.match(/(\d+)H/);
  const minutes = duration.match(/(\d+)M/);
  const seconds = duration.match(/(\d+)S/);
  let durationInMinutes = 0;
  if (hours && hours[1]) {
    durationInMinutes += parseInt(hours[1]) * 60;
  }
  if (minutes && minutes[1]) {
    durationInMinutes += parseInt(minutes[1]);
  }
  if (seconds && seconds[1]) {
    durationInMinutes += parseInt(seconds[1]) / 60;
  }

  return durationInMinutes.toFixed(2);
};

export const addQueryParam = (key, value) => {
  const url = new URL(window.location.href);
  url.searchParams.set(key, value);
  window.history.pushState({}, '', url.toString());
};

export const getDocumentCourseUnit = (
  course_units_attached_to,
  user_course_units,
  currentCourseUnitId,
) => {
  if (!user_course_units) return course_units_attached_to[0];
  if (course_units_attached_to?.length === 1) return course_units_attached_to[0];

  if (currentCourseUnitId) {
    const courseUnit = course_units_attached_to.find((cu) => cu.id === currentCourseUnitId);
    return courseUnit;
  }

  const courseUnit = course_units_attached_to.find((cu) => {
    const userCourseUnit = user_course_units.find((ucu) => ucu.id === cu.id);
    return userCourseUnit;
  });

  return courseUnit;
};

export const formatCourseUnitName = (courseUnitName) => {
  if (!courseUnitName) return '';
  // const regex = /(i{1,3}|iv|vi{0,3}|ix)$/i;
  const regex = /\b(i{1,3}|iv|vi{0,3}|ix)\b/gi;

  const modifiedName = courseUnitName.replace(regex, (match) => match.toUpperCase());

  return modifiedName;
};

const YOUTUBE_VIDEO_REGEX =
  /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?feature=player_embedded&v=))([^?&]+)/;

const VIMEO_VIDEO_REGEX = /(?:vimeo\.com\/|player\.vimeo\.com\/video\/)(\d+)/;

export const getVideoType = (videoUrl) => {
  if (!videoUrl) return null;
  if (videoUrl.match(YOUTUBE_VIDEO_REGEX)) {
    return VIDEO_TYPES.YOUTUBE;
  }
  if (videoUrl.match(VIMEO_VIDEO_REGEX)) {
    return VIDEO_TYPES.VIMEO;
  }
  return VIDEO_TYPES.CUSTOM;
};

export const getVideoId = (videoUrl, videoType) => {
  let match;
  if (videoType === VIDEO_TYPES.YOUTUBE) {
    match = videoUrl.match(YOUTUBE_VIDEO_REGEX);
  }
  if (videoType === VIDEO_TYPES.VIMEO) {
    match = videoUrl.match(VIMEO_VIDEO_REGEX);
  }

  if (match && match[1]) {
    return match[1];
  }

  return null;
};

export const retrieveVideoIdAndType = (videoUrl) => {
  const videoType = getVideoType(videoUrl);
  const videoId = getVideoId(videoUrl, videoType);
  return {
    videoId,
    videoType,
  };
};

export function getFormattedUploadDate(uploadDate) {
  const now = new Date();
  const timeDiff = now - new Date(uploadDate);
  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months =
    now.getMonth() -
    new Date(uploadDate).getMonth() +
    12 * (now.getFullYear() - new Date(uploadDate).getFullYear());
  const years = Math.floor(months / 12);

  const timeAgo = (value, unit) => `${value} ${unit}${value > 1 ? 's' : ''} ago`;

  if (years >= 1) return timeAgo(years, 'year');
  if (months >= 1) return timeAgo(months, 'month');
  if (weeks >= 1) return timeAgo(weeks, 'week');
  if (days >= 1) return timeAgo(days, 'day');
  if (hours >= 1) return timeAgo(hours, 'hour');
  if (minutes >= 1) return timeAgo(minutes, 'minute');
  return timeAgo(seconds, 'second');
}

export const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const validatePhoneNumber = (phoneNumber) => {
  const formattedNumber = phoneNumber.replace(/\s/g, '');
  const validRegex = /^(?:\+?256|0)(?:7\d{8})$/;

  if (validRegex.test(formattedNumber)) {
    // Valid number, format it to 07XXXXXXXX
    const match = formattedNumber.match(/\d+/);
    const formatted = match[0].length === 13 ? match[0].slice(-11) : match[0].slice(-9);

    return {
      phoneNumber: `0${formatted}`,
      valid: true,
    };
  }

  // Invalid number, return as is
  return {
    phoneNumber: formattedNumber,
    valid: false,
  };
};

/* eslint-disable array-callback-return */
export const getDocumentCourse = (
  user,
  isLoggedIn,
  document_course_units,
  currentCourse,
  source,
) => {
  const courses = [];
  document_course_units.map((course_unit) => {
    const course_unit_courses = course_unit.courses_attached_to;
    course_unit_courses.map((course) => {
      const checkCourse = courses.find((c) => c.id === course.id);
      if (!checkCourse) {
        courses.push(course);
      }
    });
  });

  if (courses.length < 0) return null;

  if (currentCourse && source !== 'my_documents') {
    const course = courses.find((c) => c.name === currentCourse);
    if (course) return course;
  }

  if (isLoggedIn) {
    const userCourse = courses.find((c) => c.id === user.course.id);
    if (userCourse) return userCourse;
  }
  return courses[0];
};

export const checkEnrollmentStatus = (user_course_units, course_unit) => {
  let isEnrolled = false;
  if (user_course_units?.length > 0) {
    user_course_units?.forEach((enrolledCu) => {
      if (enrolledCu.slug === course_unit) {
        isEnrolled = true;
      }
    });
  }
  return isEnrolled;
};

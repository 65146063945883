import React from 'react';
import './styles.css';

const DocumentLoader = () => (
  <div className='gpa__document_loader'>
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default DocumentLoader;

import React from 'react';
import { useSelector } from 'react-redux';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Fb } from '../../containers/Icons/Icons';
import classes from './SocialIcon.module.css';

export const SocialIcon = (props) => {
  const DarkMode = useSelector((state) => state.theme.darkMode);
  const { socialLink, socialIcon } = props;
  return (
    <a
      href={socialLink}
      target='_blank'
      rel='noopener noreferrer'
      className={`${classes.gpa__social_icon_link} ${DarkMode ? classes.gpa__dark_mode : ''}`}
    >
      {socialIcon}
    </a>
  );
};

const SocialMedia = () => {
  const DarkMode = useSelector((state) => state.theme.darkMode);

  return (
    <div
      className={`${classes.gpa__social_icons_wrapper} ${DarkMode ? classes.gpa__dark_mode : ''}`}
    >
      <SocialIcon
        socialLink='https://facebook.com/gpaelevator'
        socialIcon={<Fb fontSize='small' />}
      />
      <SocialIcon
        socialLink='https://instagram.com/gpaelevator'
        socialIcon={<InstagramIcon fontSize='small' />}
      />
      <SocialIcon
        socialLink='https://wa.link/iyj0rg'
        socialIcon={<WhatsAppIcon fontSize='small' />}
      />
      <SocialIcon
        socialLink='https://twitter.com/gpaelevator'
        socialIcon={
          <svg viewBox='0 0 24 24' aria-label='X' role='img' className='fill-current w-6 px-1'>
            <g>
              <path d='M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z' />
            </g>
          </svg>
        }
      />
      <SocialIcon
        socialLink='https://www.youtube.com/@gpaelevator'
        socialIcon={<YouTubeIcon fontSize='small' />}
      />
    </div>
  );
};
export default SocialMedia;

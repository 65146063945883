import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

// ===REDUX IMPORTS===
import { useSelector } from 'react-redux';
import { formatCourseUnitName } from 'utils';

// ===MUI IMPORTS===
import CircularProgress from '@mui/material/CircularProgress';
import classes from './Search.module.css';
import { axios, searchContentRoute } from '../../../utils/lib';

/**
 * @name getResultTitle
 * @description gets the result title and link given the result object
 * @param {string} result
 * @returns {object} resultLink, resultTitle
 */
const getResultTitle = (result) => {
  let resultLink = '';
  let resultTitle = '';
  if (result?.isUniversity) {
    resultLink = `/${result?.name}`;
    resultTitle = `${result?.name.replace(/-/g, ' ')} ~ ${result?.university_code.toUpperCase()}`;
  } else if (result?.isCourseUnit) {
    resultLink = `/${result?.courses_attached_to[0]?.university?.name}/${result?.courses_attached_to[0]?.name}/${result?.slug}`;
    resultTitle = `${result?.course_unit_code?.toUpperCase()}-${formatCourseUnitName(
      result?.name,
    ).replace(/-/g, ' ')} ~ ${result?.courses_attached_to[0]?.university?.name.replace(/-/g, ' ')}`;
  } else {
    resultLink = `/${result?.university?.name}/${result?.name}`;
    resultTitle = `${result?.name.replace(/-/g, ' ')} ~ ${result?.university?.name.replace(
      /-/g,
      ' ',
    )}`;
  }

  return {
    resultLink,
    resultTitle,
  };
};

const Search = (props) => {
  const darkMode = useSelector((state) => state.theme.darkMode);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const timeoutRef = useRef(null);

  const keyWordHandler = useCallback((event) => {
    const { value } = event.target;
    setSearchTerm(value);
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  useEffect(() => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(async () => {
      if (searchTerm.length > 2) {
        setIsLoading(true);
        const { url } = searchContentRoute(searchTerm);
        const res = await axios.get(url);
        setSearchResults(res?.data?.data);
        setIsLoading(false);
      }
    }, 500);
  }, [searchTerm]);

  const { placeholder } = props;

  const getAppropriateMessage = () => {
    if (isLoading) {
      return <p>Searching...</p>;
    }
    if (!isLoading && searchTerm.length < 3) {
      return (
        <p>
          Type at least <strong>3 characters</strong> to search
        </p>
      );
    }
    if (!isLoading && searchTerm.length > 2 && searchResults.length < 1) {
      return (
        <p>
          No results found for <strong>{searchTerm}</strong>
        </p>
      );
    }
    return <p>Search results for {searchTerm}</p>;
  };

  return (
    <>
      <div
        className={`${classes.gpa__home_search_section_search_bar_wrapper} ${
          darkMode ? classes.gpa__dark_mode : ''
        } gpa__results_dropdown_show`}
      >
        <svg
          aria-hidden='true'
          focusable='false'
          data-prefix='fas'
          data-icon='search'
          className={classes.gpa__home_search_bar_icon}
          role='img'
          viewBox='0 0 512 512'
        >
          <path
            fill='currentColor'
            d='M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z'
          />
        </svg>

        <input
          type='search'
          className={`${classes.gpa__home_search_bar_input_area} ${
            darkMode ? ' text-[#fff]/80' : ' text-black/80'
          } `}
          autoComplete='off'
          placeholder={placeholder}
          spellCheck='true'
          value={searchTerm}
          name='search'
          onChange={keyWordHandler}
        />
        {searchTerm.length > 0 && (
          <span className={classes.gpa__search_result_count}>{searchResults.length}</span>
        )}
        {isLoading && <CircularProgress color='secondary' size={24} thickness={4} />}
      </div>
      {searchTerm.length > 0 && (
        <div
          className={`${classes.gpa__home_search_section_search_results_wrapper} ${
            darkMode ? classes.gpa__dark_mode : ''
          }`}
        >
          <ul
            className={`${classes.gpa__home_search_section_search_results_dropdown} ${
              darkMode ? classes.gpa__dark_mode : ''
            } ${searchResults && classes.gpa__home_search_section_search_results_dropdown_show} ${
              searchResults.length < 0 ? classes.gpa__no_search_found_wrapper : ''
            }`}
          >
            {searchResults.length > 0 ? (
              searchResults.map((result) => {
                const { resultLink, resultTitle } = getResultTitle(result);
                /* eslint-disable no-underscore-dangle */
                return (
                  <Link to={`${resultLink}`} key={result._id}>
                    <li
                      className={`mx-2  ${classes.gpa__custom_search_result_wrapper} ${
                        darkMode ? classes.gpa__dark_mode : ''
                      }`}
                    >
                      <div className={classes.gpa__custom_search_result_inner}>
                        <div className={classes.gpa__result_title}>{resultTitle}</div>
                        <div className={classes.gpa__result_url}>
                          {`https://gpaelevator.com${resultLink}`}
                        </div>
                      </div>
                    </li>
                  </Link>
                );
              })
            ) : (
              <div style={{ padding: 8, overflow: 'hidden' }}>{getAppropriateMessage()}</div>
            )}
          </ul>
        </div>
      )}
    </>
  );
};

export default Search;

import { GPA_APIS } from 'adapters';
import { useState, useEffect } from 'react';

let cachedUniversities = [];

const useUniversities = (refresh) => {
  const [universities, setUniversities] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchUniversities = async () => {
    if (cachedUniversities.length > 0 && !refresh) {
      setUniversities(cachedUniversities);
      return;
    }
    try {
      setLoading(true);
      const res = await GPA_APIS.university.get.getAllUniversities();
      const data = res.data.universities;
      cachedUniversities = data;
      setUniversities(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUniversities();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return { universities, loading };
};

export default useUniversities;

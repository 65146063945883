import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Paper } from '@mui/material';
import classes from './HeroSection.module.css';

const HeroSection = () => {
  const DarkMode = useSelector((state) => state.theme.darkMode);
  const isAuthenticated = useSelector((state) => state.auth.isLoggedIn);

  return (
    <div className={classes.gpa__hero_section_wrapper}>
      <div
        className={`${DarkMode ? classes.gpa__dark_mode : ''} ${
          classes.gpa__hero_section_image_wrapper
        }`}
      />
      <div className={classes.gpa__hero_section_content_wrapper}>
        <Paper elevation={0} className={classes.gpa__hero_section_welcome_paper}>
          <h1 className={classes.gpa__hero_section_content_title}>
            Everything you need to <br />
            improve your grades
          </h1>
          <p className={classes.gpa__hero_section_content_subtitle}>
            Get unlimited access to free study guides like Past Papers, practice <br />
            questions, lecture notes, assignments, solutions and much more!
          </p>
          <div className={classes.gpa__hero_section_content_action_button_wrapper}>
            <Button
              component={Link}
              to={isAuthenticated ? `/dashboard/user` : `/login`}
              variant='contained'
              color='primary'
              className='hover:!text-white'
            >
              Get Started
            </Button>
          </div>
        </Paper>
      </div>
    </div>
  );
};
export default HeroSection;

import React from 'react';
import { useSelector } from 'react-redux';
import classes from './DrawerToggleButton.module.css';

const DrawerToggleButton = (props) => {
  const DarkMode = useSelector((state) => state.theme.darkMode);

  const { click } = props;
  return (
    <button
      type='button'
      className={`${classes.gpa__toggle_button} ${DarkMode ? classes.gpa__dark_mode : ''}`}
      onClick={click}
    >
      <div className={classes.gpa__toggle_button_item} />
      <div className={classes.gpa__toggle_button_item} />
      <div className={classes.gpa__toggle_button_item} />
    </button>
  );
};

export default DrawerToggleButton;

import React from "react";
import { useSelector } from "react-redux";
import { Container, Row } from "react-bootstrap";
import { Divider } from "@mui/material";

import FooterSection from "./FooterSection/FooterSection";
import AboutSection from "../AboutUsSection/AboutSection";
import classes from "./DefaultFooter.module.css";
import SocialMedia from "../../SocialMedia/SocialMedia";
import CopyRight from "../CopyRight/CopyRight";
import ContactSection from "../ContactUsSection/ContactSection";
import QuickLinkSection from "../QuickLinks/QuickLinkSection";
import HelpCenterSection from "../HelpCenter/HelpCenterSection";

const DefaultFooter = () => {
  const DarkMode = useSelector((state) => state.theme.darkMode);

  return (
    <Container
      fluid
      className={`${classes.gpa__default_footer_section_wrapper} ${
        DarkMode ? classes.gpa__dark_mode : ""
      }`}
    >
      <Row>
        <FooterSection
          sectionTitle="About Us"
          sectionContent={<AboutSection />}
        />
        <FooterSection
          sectionTitle="Contact Us"
          sectionContent={<ContactSection />}
        />
        <FooterSection
          sectionTitle="Quick Links"
          sectionContent={<QuickLinkSection />}
        />
        <FooterSection
          sectionTitle="Follow Us"
          sectionContent={<SocialMedia />}
        >
          <h5>Help Center</h5>
          <HelpCenterSection />
        </FooterSection>
      </Row>
      <Divider variant="middle" style={{ marginTop: "20px" }} />
      <CopyRight />
    </Container>
  );
};
export default DefaultFooter;

/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  chat: {},
  chats: [],
  messages: [],
  isLoading: false,
  isLoadingMessages: false,
  notifications: [],
};

export const chatSlice = createSlice({
  initialState,
  name: 'chatSlice',
  reducers: {
    setChatLoading(state, { payload }) {
      state.isLoading = payload;
    },
    setMessagesLoading(state, { payload }) {
      state.isLoadingMessages = payload;
    },
    clearMessages(state) {
      state.messages = [];
    },
    setActiveChat(state, { payload }) {
      state.chat = payload;
    },
    getUserChats(state, { payload }) {
      state.chats = payload;
    },
    getChatDetails(state, { payload }) {
      state.chat = payload;
    },
    getChatMessages(state, { payload }) {
      state.messages = payload;
    },
    sendNotification(state, { payload }) {
      const isNotification =
        state.notifications.findIndex((not) => not._id === payload?._id) === -1;
      if (!isNotification) {
        state.notifications = [...state.notifications, payload];
        localStorage.setItem('userNotifications', JSON.stringify(state.notifications));
      }
    },
    clearNotifications(state, { payload }) {
      state.notifications = payload;
      localStorage.setItem('userNotifications', JSON.stringify(payload));
    },
  },
});
const { reducer, actions } = chatSlice;

export const {
  setChatLoading,
  setMessagesLoading,
  setActiveChat,
  getUserChats,
  getChatDetails,
  getChatMessages,
  clearMessages,
  sendNotification,
  clearNotifications,
} = actions;

export default reducer;

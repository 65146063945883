import React from 'react';
import { useSelector } from 'react-redux';
//= =MUI IMPORTS==

//= ==COMPONENT IMPORTS===
import { useMediaQuery } from '@mui/material';
import LoginButton from './LoginButton/LoginButton';
import RegisterButton from './RegisterButton/RegisterButton';
import UserAvarter from '../AuthenticatedUser/UserAvatar/UserAvatar';
import styles from './Membership.module.css';
import DarkModeToggle from '../UI/DarkModeToggle/DarkModeToggle';
import Hidden from '../../containers/Hidden/Hidden';
import './styles.scss';

const Membership = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const matches = useMediaQuery('(min-width:769px)');
  return (
    <div className={styles.gpa__membership_section}>
      <div className={`${!matches ? 'hidden' : ''}`}>
        <DarkModeToggle />
      </div>
      {isLoggedIn ? (
        <>
          <UserAvarter />
        </>
      ) : (
        <>
          <Hidden xsDown>
            <LoginButton />
            <RegisterButton />
          </Hidden>
          <Hidden smUp>
            <LoginButton />
          </Hidden>
        </>
      )}
    </div>
  );
};

export default Membership;

import React from 'react';
import { NavLink } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/DoubleArrow';
import styles from './FooterNavigation.module.css';

const NavigationItem = (props) => {
  const { itemTitle, itemLink, valid } = props;
  return (
    <NavLink
      to={itemLink}
      className={({ isActive }) => `${isActive && valid ? `${styles.gpa__footer_nav_active}` : ''}`}
    >
      <li>
        <MenuIcon fontSize='small' className={styles.gpa__footer_section_nav_item_icon} />
        {itemTitle}
      </li>
    </NavLink>
  );
};

export default NavigationItem;

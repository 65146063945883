import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

//= ==COMPONENT IMPORTS===
import classes from "./UserMenuItem.module.css";

const UserMenuItem = (props) => {
  const DarkMode = useSelector((state) => state.theme.darkMode);

  const { itemLink, itemIcon, itemTitle, badgeMessage, onClick } = props;

  return (
    <Link
      to={`/dashboard/${itemLink}`}
      className={classes.gpa__link_item_bagde}
      onClick={onClick}
    >
      <li>
        {itemIcon} {itemTitle}
        {badgeMessage && (
          <span
            className={`${classes.gpa__link_item_bagde_message_wrapper} ${
              DarkMode ? classes.gpa__dark_mode : ""
            }`}
          >
            {badgeMessage}
          </span>
        )}
      </li>
    </Link>
  );
};
export default UserMenuItem;

import React, { useEffect, useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { extendSession } from '../store/Actions/AuthActions';
import { setShowTimeUpPrompt } from '../store/Slices/uiSlice';
import { SHOW_PROMPT_TIME } from './constants';

const SHOW_TIME_IN_SECONDS = SHOW_PROMPT_TIME / 1000;

const TimeUpPrompt = () => {
  const user = useSelector((state) => state.auth.user);
  const [countIntervalId, setCountIntervalId] = useState('');
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(SHOW_TIME_IN_SECONDS);
  const dispatch = useDispatch();

  useEffect(() => {
    const countInterval = setInterval(() => {
      setCount((prev) => prev - 1);
    }, 1000);
    setCountIntervalId(countInterval);

    return () => {
      clearInterval(countInterval);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (count < 1) {
      clearInterval(countIntervalId);
    }
  }, [count, countIntervalId]);

  const ExtendSession = async () => {
    const timerIds = JSON.parse(localStorage.getItem('gptids')) || [];
    timerIds.forEach((id) => {
      clearTimeout(id);
    });
    setLoading(true);
    const data = {
      email: user?.email,
    };
    await dispatch(extendSession(data));
    localStorage.removeItem('gptids');
    setLoading(false);
  };

  const handleClose = () => {
    clearInterval(countIntervalId);
    dispatch(setShowTimeUpPrompt(false));
  };

  return (
    <div className='py-3'>
      <p>
        Hi <strong className='capitalize'>{user?.first_name}</strong>,
      </p>
      <p>
        Your session is expiring in <strong>{count}</strong> seconds, would you like to extend your
        session for more 2 hours
      </p>
      <div className='flex justify-between gap-3 pb-2 mt-3'>
        <Button variant='contained' color='error' onClick={handleClose}>
          Cancel
        </Button>
        <Button variant='contained' color='primary' disabled={loading} onClick={ExtendSession}>
          {loading ? 'Extending...' : 'Extend Session'}
          {loading ? <CircularProgress thickness={2} size={20} style={{ marginLeft: 5 }} /> : null}
        </Button>
      </div>
    </div>
  );
};

export default TimeUpPrompt;

import { GPA_APIS } from 'adapters';
import { useState, useEffect } from 'react';

const useUnverifiedUsers = (startDate, refresh) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await GPA_APIS.auth.get.getUnverifiedUsers(startDate);
      const data = res.data.data.unverifiedUsers;
      setUsers(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, startDate]);

  return { users, loading };
};

export default useUnverifiedUsers;

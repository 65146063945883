import { GPA_APIS } from 'adapters';
import { useEffect, useState } from 'react';

const useRelatedCourseUnits = (courseUnit, courseId) => {
  const [loading, setLoading] = useState(false);
  const [relatedCourseUnits, setRelatedCourseUnits] = useState([]);

  const fetchData = async () => {
    if (!courseUnit || !courseId) return;
    setLoading(true);
    const year = courseUnit?.year;
    const semester = courseUnit?.semester;
    const response = await GPA_APIS.courseUnit.get.getCourseUnitsByCourseId(
      courseId,
      year,
      semester,
    );
    const filteredUnits = response.data.courseUnits.filter((unit) => unit.id !== courseUnit.id);
    setRelatedCourseUnits(filteredUnits);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseUnit, courseId]);

  return { relatedCourseUnits, loading };
};

export default useRelatedCourseUnits;

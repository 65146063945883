import React from 'react';
import classes from './Hidden.module.css';

const Hidden = (props) => {
  const { xsDown, children } = props;
  return (
    <div className={xsDown ? classes?.gpa__show_large : classes?.gpa__show_small}>{children}</div>
  );
};
export default Hidden;

import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ title, description, image }) => (
  <Helmet>
    <title>{title} ~ gpa elevator</title>
    <meta name='description' content={description} />
    {image && <meta property='og:image' content={image} />}
  </Helmet>
);

export default SEO;

import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

//= ==MUI IMPORTS===
import Toolbar from '@mui/material/Toolbar';

//= ==COMPONENT IMPORTS===
import Membership from '../Membership/Membership';
import MobileSideDrawer from '../SideDrawer/MobileSideDrawer';
import GpaLogo from './GpaLogo';
import classes from './AppBar.module.css';

const AppBar = () => {
  const DarkMode = useSelector((state) => state.theme.darkMode);

  const location = useLocation();

  if (location.pathname !== '/login') {
    localStorage.setItem('previousLocation', location.pathname);
  }

  return (
    <Toolbar id='gpa__back_to_top_anchor'>
      <div className={`${classes.gpa__AppBar_wrapper} ${DarkMode ? classes.gpa__dark_mode : ''}`}>
        <MobileSideDrawer />
        <div className={classes.gpa__AppBar_logo_Wrapper}>
          <GpaLogo />
        </div>
        <div className={classes.gpa__AppBar_spacer} />

        <Membership />
      </div>
    </Toolbar>
  );
};
export default AppBar;

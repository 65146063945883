/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  status: '',
  message: '',
};
const newDocumentSlice = createSlice({
  name: 'newdocument',
  initialState,
  reducers: {
    newDocumentLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
  },
});

const { reducer, actions } = newDocumentSlice;

export const { newDocumentLoading } = actions;
export default reducer;

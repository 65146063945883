import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

//= ==MUI IMPORTS===
import { handleMenuPopup } from 'store/Slices/uiSlice';
import { Link } from 'react-router-dom';
import { Badge, Avatar, Tooltip, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';

//= ==COMPONENT IMPORTS===
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import styles from './AvatarItem.module.css';
import UserName from '../UserProfileCard/UserName';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid #fff',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const AvatarItem = () => {
  const showPopup = useSelector((state) => state.ui.showUserMenu);
  const darkMode = useSelector((state) => state.theme.darkMode);
  const CurrentUser = useSelector((state) => state.auth.user);
  const matches = useMediaQuery('(min-width:769px)');
  const hasProfilePicture = CurrentUser?.image && CurrentUser?.image?.length > 5;
  const dispatch = useDispatch();

  const handleShowMenu = (e) => {
    e.stopPropagation();
    dispatch(handleMenuPopup(!showPopup));
  };

  const isUserAccount = window.location.pathname === '/dashboard/user';

  return (
    <div className='px-1 flex items-end gap-2'>
      {!isUserAccount && !matches && (
        <Link
          to='/dashboard/user'
          className={`no-underline ${darkMode ? '!text-primary' : '!text-white'}`}
        >
          My Account
        </Link>
      )}
      <StyledBadge
        className={`${styles.gpa__user_profile_wrapper} header-navigation-item ${
          darkMode ? styles.gpa__dark_mode : ''
        }`}
        overlap='circular'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        variant='dot'
      >
        <Tooltip
          title={showPopup ? 'Click to close menu' : 'Click to view profile'}
          placement='left'
          arrow
        >
          <Avatar
            onClick={handleShowMenu}
            className={`cursor-pointer w-full ${styles.gpa__user_profile} ${
              darkMode ? 'gpa__dark_mode' : ''
            }`}
            alt={CurrentUser?.first_name}
            src={hasProfilePicture && CurrentUser.image}
          >
            <UserName />
          </Avatar>
        </Tooltip>
        <div
          onClick={(e) => e.stopPropagation()}
          className={`header-navigation-item__dropdown${
            showPopup ? ' header-navigation-item__dropdown--active' : ''
          } ${darkMode ? 'gpa__dark_mode' : ''}`}
        >
          <DropdownMenu onMenuClick={handleShowMenu} />
        </div>
      </StyledBadge>
      {!isUserAccount && matches && (
        <Link
          to='/dashboard/user'
          className={`no-underline ${darkMode ? '!text-primary' : '!text-white'}`}
        >
          My Account
        </Link>
      )}
    </div>
  );
};

export default AvatarItem;

/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  courseUnitDetails: {},
  isLoading: false,
  error: '',
  message: '',
  usersEnrolled: 0,
  EnrolledUserList: [],
  isLoadingUsers: false,
};
const courseUnitSlice = createSlice({
  name: 'courseUnitDetails',
  initialState,
  reducers: {
    fetchCourseUnitPending: (state) => {
      state.isLoading = true;
    },
    fetchCourseUnitSuccess: (state, action) => {
      state.courseUnitDetails = action.payload;
      state.isLoading = false;
    },
    fetchCourseUnitFail: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
    fetchEnrolledUsersPending: (state) => {
      state.isLoadingUsers = true;
    },
    fetchEnrolledUsersSuccess: (state, { payload }) => {
      state.isLoadingUsers = false;
      state.usersEnrolled = payload.number_of_students_enrolled;
      state.EnrolledUserList = payload.students;
    },
    fetchEnrolledUsersFail: (state, { payload }) => {
      state.isLoadingUsers = false;
      state.message = payload.status;
    },
  },
});

const { reducer, actions } = courseUnitSlice;

export const {
  fetchCourseUnitPending,
  fetchCourseUnitSuccess,
  fetchCourseUnitFail,
  fetchEnrolledUsersPending,
  fetchEnrolledUsersSuccess,
  fetchEnrolledUsersFail,
} = actions;
export default reducer;

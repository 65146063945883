import React from 'react';
import './Loader.css';

const PaymentLoader = () => (
  <div className='gpa-payment-spinner'>
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default PaymentLoader;

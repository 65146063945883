import { GPA_APIS } from 'adapters';
import { DOCUMENT_CATEGORIES } from 'constants';
import { useState, useEffect } from 'react';

const useCategoryDocuments = (courseUnit, category) => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDocuments = async () => {
      if (!courseUnit?.id || !category) {
        setLoading(false);
        setDocuments([]);
        return;
      }
      const categoryName = DOCUMENT_CATEGORIES.find((cat) => cat.link === category)?.name;
      const categoryToFetch = courseUnit?.documentSummary.find(
        (doc) => doc.name?.toLowerCase() === categoryName?.toLowerCase(),
      );
      if (categoryToFetch?.totalCount === 0) {
        setDocuments([]);
        setLoading(false);
        return;
      }
      setLoading(true);
      const res = await GPA_APIS.courseUnit.get.categoryDocuments(
        courseUnit?.id,
        DOCUMENT_CATEGORIES.find((cat) => cat.link === category)?.name,
      );
      setDocuments(res.data?.files);
      setLoading(false);
    };

    fetchDocuments();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseUnit, category]);

  return { documents, loading };
};

export default useCategoryDocuments;

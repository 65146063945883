/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  document: {},
  isLoading: false,
};
const documentSlice = createSlice({
  name: 'documentDetails',
  initialState,
  reducers: {
    setDocumentLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    fetchDocumentSuccess: (state, action) => {
      state.document = action.payload;
    },
  },
});

const { reducer, actions } = documentSlice;

export const { setDocumentLoading, fetchDocumentSuccess } = actions;
export default reducer;

/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  courseDetails: {},
  usersEnrolled: 0,
  EnrolledUsers: [],
  isLoading: false,
  isLoadingUsers: false,
  message: '',
  status: '',
};
const courseSlice = createSlice({
  name: 'courseSlice',
  initialState,
  reducers: {
    setCourseLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    fetchCourseDetailsLoading: (state) => {
      state.isLoading = true;
    },
    fetchCourseDetailsSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.courseDetails = payload;
    },
    fetchCourseDetailsFail: (state, { payload }) => {
      state.isLoading = false;
      state.courseDetails = payload;
      state.message = payload.message;
    },
    fetchCourseUsersPending: (state) => {
      state.isLoadingUsers = true;
    },
    fetchCourseUsersSuccess: (state, { payload }) => {
      state.isLoadingUsers = false;
      state.usersEnrolled = payload.number_of_students_enrolled;
      state.EnrolledUsers = payload.students;
    },
    fetchCourseUsersFail: (state, { payload }) => {
      state.isLoadingUsers = false;
      state.message = payload.status;
    },
  },
});

const { reducer, actions } = courseSlice;

export const {
  fetchCourseDetailsLoading,
  fetchCourseDetailsSuccess,
  fetchCourseDetailsFail,
  fetchCourseUsersPending,
  fetchCourseUsersSuccess,
  fetchCourseUsersFail,
  setCourseLoading,
} = actions;
export default reducer;

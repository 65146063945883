// ====CUSTOM EXTERNAL LINKS====
export const TRACKING_ID = 'UA-214184042-1';
export const EMAIL_USER_ID = 'user_CzgKz7PqjX56Ys5N4TVl0';
export const EMAIL_SERVICE_ID = 'service_9vihhqp';
// export const EMAIL_USER_ID = "user_ZNOSK4ZDLljl2gj6tBV6r";
// export const EMAIL_SERVICE_ID = "service_t61k9cn";

// ====EMAIL TEMPLATE IDs
export const NEW_VOLUNTEER_EMAIL_TEMPLATE = 'template_foff27q';

// ====DOCUMENT DOWNLOAD REGISTER RECORDS====//
export const SHEET_URL =
  'https://script.google.com/macros/s/AKfycbwTvjqb1x4pKnTSHCVy5pMMidawWI5bX9frtPOun_k3t_aSy4QU2wgh8cpCVGDLrkRC/exec';

// ====REGISTER SHEET IDS
export const DOWNLOAD_SHEET_ID = '105125458';
export const DOCUMENT_ACCESS_SHEET_ID = '679563151';
export const UPLOADED_DOCUMENTS_SHEET_ID = '989991124';
export const LOGIN_SHEET_ID = '1244428880';
export const VOLUNTEERS_SHEET_ID = '1947805476';
export const PAYMENT_ATTEMPT_SHEET_ID = '1499220169';

// export const GOOGLE_MAP_API_KEY = "AIzaSyCs0WzxgckFYTk4wgL4KmEcYFPCbV3z1-c";

// ====REGISTER ADMIN CREDENTIALS=====
export const CLIENT_EMAIL = 'gpa-elevator@gpa-elevator-337420.iam.gserviceaccount.com';
export const PRIVATE_KEY =
  '-----BEGIN PRIVATE KEY-----\nMIIEvwIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQDV3ynsruotGtrd\nYPVLbm3B5dNFbzyfzjHNBMTuoi3JP7Egxo9+vPMZ5bMqAPcXrKDSC/cZhguRRceb\npgNhtls8atAQFKlCNqIShTqFURqBuS98vRx/hWnPH7ycpnm4hsu8RXW3KYEIaniU\nES33q3N4YYa7cgH28h+4Ie5FLdybMwqD928Gh1bWW9sI+LXQ2K8dGyz2SCPo7cha\nW2K9xjVJ7i3sfAFxAL1iIG3sQAazWnrC+T4oJ1qy1Xd4fsGk+nWouUQrCGBwV8NB\nEmBqLlVT5RYjNacmJqpYYA+votnSCNfjk9EHzFxLh9dviU/Ra1CABY9Go0FUxR2Q\nlLf0dWw9AgMBAAECggEAFtOUey85DbrbyLyAOb6Nxt/jcnn6n7jHbnEXnrufBOeA\nHXYr2xRKIwW132IOfU+es4R06Vu58NWghg+MNVnb94ET919pKEjB+BWRpyh+moFc\nHOVnPQINhr6FqDeJbsUuSggk1fl/TNlmSLFjtVcughbwpSF4LbXuZj3y2x3XuZWh\nP+dnB/TgWosvc23z9flO8moC+UuBNf5X4undn8enAR8/vkbdG9nSB1BfUwVYqHpY\nSfRkszrPTDit32lv3Y1aBoTVqRYzSjsyCPwN9gK3/tACLmFSmim+bV0JL4XiQUJL\nVEY3KAg4y5fWb5tf+zPONEVmcwaHMn3GUi6ooQBs8QKBgQD3NhKDi/2AmkAxfcNQ\nkgfHQ0pieUPIkTcWYEFVpiN5tBtsHCfSxjiMzkwnnaO1TazXd7RVgpRex1gfYYCp\n8MYax54tTlFgK1cS2GQ3IVFrrWpmk4ObGLnaeU28ZOraPIFNU+r5iMBTSczbZNWT\nCS+36amksGFHDgw5XMk2zBeS8QKBgQDdeakxXo/dQ8PTPnbY8j3H9Lefyy8RRpVZ\nUOCr/9O/HburCgg+akLBMWjHVx9tSGYo3nQ8D21JRT11EqKV0vU6bhSmhli6E01M\nkdnMW5FKQHy3nfy6TNtF9CdlNsnByBt4f0L4qgJoJv4KsUnvnXvlAMQ3ECIAGELP\n34uJXkZWDQKBgQDCZ+FzVZmLksI8qVv5dn1rdD2A50Ao8BnUHOoEQtzPDpYqFJI0\nrPE3udwlo1Bkeasc3uQQAtpPFpD0TKbz/Yas9KItmtNfv3WAOm/E8wymkv+Dnyn1\nxCIT7yT0rKAL81zzxLSae7JTTUwulENol+bQyI5CFvrI+AEg8xjBYQqA4QKBgQDX\ndAJXR8G2o7S+LRcbB9V4TyXPKq9YJA0oPVjDmnVYh8lIo9LPPTpBjRp8SdIF0xen\nDHmQ8AUF8XjPCljmXbo4pFwsf6eTJTHemkiB2FZoIIHzcpsodZoe3FzyxrDRdiTb\nPRpeo60Bu2UJ69ycYNzlM7CuTGB3LSLZ7INnGDJehQKBgQDiwAR8xWZ/GK9Qd/U6\nyBk+VW6YEY68HEn3qRM+PBTbpX6QDqvq3aji6XALL9Ib0Fie/GiU8WGXn7fwQDHA\npBaDEd7dpjPKmTbFC2GsLrPx6avqh2uEs6lgdcF099cUcQ8JOe7FjioiX49o1lDD\nMxSlxIL4AwvpO3F6cosG6DuAMA==\n-----END PRIVATE KEY-----\n';

// ====RECORD TYPE=====
export const RECORD_TYPE = {
  DOWNLOAD: 'DOWNLOAD',
  DOCUMENT_ACCESS: 'DOCUMENT_ACCESS',
  DOCUMENT_UPLOAD: 'DOCUMENT_UPLOAD',
  LOGIN: 'LOGIN',
  VOLUNTEER: 'VOLUNTEER',
  PAYMENT_ATTEMPT: 'PAYMENT_ATTEMPT',
};

export const DOCUMENT_TYPE = {
  VIDEO_TYPE: 'video',
  PDF_TYPE: 'pdf',
};

export const LECTURE_CATEGORY = {
  VIDEO_LECTURE: 'Video Lectures',
};

export const MAXIMUM_FILE_SIZE = 40 * 1024 * 1024; // 40Mbs

export const REDIRECT_URL_AFTER_LOGIN = '/dashboard/my-course-units';

export const SHOW_PROMPT_TIME = 30 * 1000; // show extend session prompt with 30 seconds left
export const DOCUMENT_PAGE_SIZE = 10;
export const DOCUMENT_PAGE_SIZE_RELATED = 5;
export const DOCUMENT_PAGE_SIZE_CU = 15;
export const USER_COURSE_UNIT_DASHBOARD = 8;
export const COURSE_COURSE_UNITS = 20;
export const DOCUMENT_PAGE_SIZE_CU_DASHBOARD = 10;
export const DOTS = '...';

export const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

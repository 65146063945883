import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GPA_APIS } from 'adapters';

const useCourseUnit = ({ uni_name, course_name, course_unit_slug }) => {
  const [loading, setLoading] = useState(true);
  const [courseUnit, setCourseUnit] = useState(null);
  const [usersEnrolled, setUsersEnrolled] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourseUnit = async () => {
      if (!uni_name || !course_name || !course_unit_slug) return;
      try {
        setLoading(true);
        const response = await GPA_APIS.courseUnit.get.courseUnitDetailsByUniNameAndCourseName({
          universityName: uni_name,
          courseName: course_name,
          courseUnitSlug: course_unit_slug,
        });
        setUsersEnrolled(response.data.studentsEnrolled);
        setCourseUnit(response.data.courseUnit);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error?.response?.status === 404) {
          setCourseUnit(null);
          navigate(`/${uni_name}/${course_name}`);
        }
      }
    };
    fetchCourseUnit();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course_unit_slug]);

  return { courseUnit, usersEnrolled, loading };
};

export default useCourseUnit;

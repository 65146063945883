import React, { useState } from 'react';

import { useSelector } from 'react-redux';
// import CloseIcon from '@mui/icons-material/CloseOutlined';
import { Button, SwipeableDrawer } from '@mui/material';
import SideDrawerContent from './SideDrawerContent/SideDrawerContent';
import DrawerToggleButton from './DrawerToggleButton';
import Wrapper from '../../containers/hoc/wrapper';
import classes from './MobileSideDrawer.module.css';

const MobileSideDrawer = () => {
  const darkMode = useSelector((state) => state.theme.darkMode);

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [showCloseButton, setShowCloseButton] = useState(false);

  const toggleDrawer = (anchor, open) => (event) => {
    let timerId;
    if (open) {
      timerId = setTimeout(() => {
        setShowCloseButton(true);
      }, 300);
    } else {
      setShowCloseButton(false);
      clearTimeout(timerId);
    }
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const sideDrawerCloseHandler = () => {
    setState({ ...state, left: false });
    setShowCloseButton(false);
  };

  return (
    <Wrapper>
      {['left'].map((anchor) => (
        <Wrapper key={anchor}>
          <div className='flex md:hidden'>
            <Button onClick={toggleDrawer(anchor, true)}>
              <DrawerToggleButton />
            </Button>
          </div>

          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
            className={classes.gpa__mobile_drawer}
          >
            <SideDrawerContent DrawerClose={sideDrawerCloseHandler} />

            {state[anchor] && showCloseButton && (
              <div
                onClick={sideDrawerCloseHandler}
                className={`shadow-md ${classes.gpa__mobile_menu_toggle} ${
                  darkMode ? classes.gpa__dark_mode : ''
                }`}
              >
                <svg
                  height='32'
                  viewBox='0 0 1000 1000'
                  width='32'
                  xmlns='http://www.w3.org/2000/svg'
                  className='cursor-pointer'
                >
                  <path d='M159 204l55-54 659 659-55 55-659-660m709 5L205 877l-55-59 664-664' />
                </svg>
              </div>
            )}
          </SwipeableDrawer>
        </Wrapper>
      ))}
    </Wrapper>
  );
};
export default MobileSideDrawer;

/**
 * Handle all the API calls to handle university apis
 * @name universityRequests
 * @param {(config: RequestConfig)=> any} requestor
 */
export const universityRequests = (requestor) => ({
  /**
   * @name createNewUniversity
   * @description create a new university
   * @param {object} data
   * @returns {Promise<any>}
   */
  createNewUniversity: async (data) => {
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('name', data.name);
    formData.append('university_code', data.university_code);
    formData.append('description', data.description);
    formData.append('courseCategories', JSON.stringify(data.courseCategories));
    formData.append('links', JSON.stringify(data.links));

    const url = `/universities/register`;
    const response = await requestor({
      method: 'POST',
      url,
      data: formData,
    });
    return response;
  },

  /**
   * @name editUniversityDetails
   * @description edit university details
   * @param {object} data
   * @param {string} universityId
   * @returns {Promise<any>}
   */
  editUniversityDetails: async (data, universityId) => {
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('name', data.name);
    formData.append('university_code', data.university_code);
    formData.append('description', data.description);
    formData.append('courseCategories', JSON.stringify(data.courseCategories));
    formData.append('links', JSON.stringify(data.links));

    const url = `/universities/edit/${universityId}`;
    const response = await requestor({
      method: 'PATCH',
      url,
      data: formData,
    });
    return response;
  },
  /**
   * @name get - handle all get requests for university
   */
  get: {
    /**
     * Get university from the database using the name
     * @name getUniversityByName
     * @param {string} universityName
     * @returns {Promise<any>}
     */
    getUniversityByName: async (universityName) => {
      const url = `universities?name=${universityName}`;
      const response = await requestor({
        method: 'GET',
        url,
      });
      return response;
    },
    /**
     * Get all universities from the database
     * @name getAllUniversities
     * @returns {Promise<any>}
     */
    getAllUniversities: async () => {
      const url = `/universities/getAll`;
      const response = await requestor({
        method: 'GET',
        url,
      });
      return response;
    },
  },
});

import React from 'react';
import { NavLink } from 'react-router-dom';

// ===REDUX IMPORTS===
import { useSelector } from 'react-redux';

// ===COMPONENT IMPORTS===
import classes from './RegisterButton.module.css';
import styles from '../Membership.module.css';

const Register = (props) => {
  const DarkMode = useSelector((state) => state.theme.darkMode);
  const { DrawerClose } = props;
  return (
    <NavLink
      className={({ isActive }) =>
        `${styles.gpa__membership} ${DarkMode ? classes.gpa__dark_mode : ''}` +
        ` ${classes.gpa__register}${isActive ? ` ${classes.gpa__active_link}` : ''}`
      }
      to='/register'
      onClick={DrawerClose}
    >
      Register
    </NavLink>
  );
};

export default Register;

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GPA_APIS } from 'adapters';
import { saveDocumentAccessRecord } from 'utils';
import { fetchDocumentSuccess, setDocumentLoading } from 'store/Slices/documentSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const useDocument = (documentId) => {
  const user = useSelector((state) => state.auth.user);
  const [document, setDocument] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { course_unit_slug, course_name, uni_name } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const getDocumentDetails = async () => {
      if (!documentId || !navigator.onLine) return;
      setIsLoading(true);
      dispatch(setDocumentLoading(true));
      const isAuthorized = await GPA_APIS.auth.get.checkAuthorization(documentId);
      if (!isAuthorized) {
        setIsLoading(false);
        toast.error(
          'You are not authorized to view this document, Please check your access points.',
        );
        navigate(`/${uni_name}/${course_name}/${course_unit_slug}`);
        return;
      }

      try {
        const response = await GPA_APIS.document.get.documentDetails(documentId);
        const documentDetails = response.data?.documentDetails;

        const courseUnit = response.data?.documentDetails?.course_units?.find(
          (cu) => cu.slug === course_unit_slug,
        );

        const documentWithCourseUnit = {
          ...documentDetails,
          course_unit: courseUnit,
        };

        setDocument(documentWithCourseUnit);
        dispatch(fetchDocumentSuccess(documentWithCourseUnit));
        setIsLoading(false);
        dispatch(setDocumentLoading(false));

        // RECORD DOCUMENT ACCESS DETAILS TO THE REGISTER
        saveDocumentAccessRecord(documentWithCourseUnit, user);

        // UPDATE DOCUMENT VIEW COUNT
        GPA_APIS.document.updateViewCount(documentId);
      } catch (error) {
        setIsLoading(false);
        dispatch(setDocumentLoading(false));
      }
    };

    getDocumentDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId]);

  return { document, isLoading };
};

export default useDocument;

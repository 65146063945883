import React from "react";

//= ==COMPONENT IMPORTS===
import HeroSection from "./HeroSection/HeroSection";
import SearchSection from "./SearchSection/SearchSection";
import Universities from "../Universities/Universities";
import PopularContent from "./PopularSection/Popular";

const Home = () => (
    <>
      <HeroSection />
      <SearchSection />
      <Universities />
      <PopularContent />
    </>
  );
export default Home;

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const useCurrentCourse = (courses) => {
  const { course_name } = useParams();
  const [course, setCourse] = useState(null);

  const getCourse = () => {
    if (courses?.length < 1) {
      setCourse(null);
      return;
    }
    if (courses?.length === 1) {
      setCourse(courses[0]);
      return;
    }

    const currentCourse = courses?.find((c) => c.name === course_name);
    if (!currentCourse) return;
    setCourse(currentCourse);
  };

  useEffect(() => {
    getCourse();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courses, course_name]);

  return course;
};

export default useCurrentCourse;

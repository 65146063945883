import React from 'react';
import { useSelector } from 'react-redux';

//= ==MUI IMPORTS===
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MessageIcon from '@mui/icons-material/Message';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { ManageAccountsIcon, NotificationsIcon } from '../../../containers/Icons/Icons';

//= ==COMPONENT IMPORTS===
import UserProfileCard from '../UserProfileCard/UserProfileCard';
import LogOutButton from '../../Membership/LogoutButton/LogoutButton';
import classes from './DropdownMenu.module.css';
import UserMenuItem from './UserMenuItem';

const DropdownMenu = ({ onMenuClick }) => {
  const darkMode = useSelector((state) => state.theme.darkMode);

  const menuItems = [
    {
      itemLink: 'my-course-units',
      itemIcon: <MenuBookIcon />,
      itemTitle: 'My course units',
    },
    {
      itemLink: 'notifications',
      itemIcon: <NotificationsIcon />,
      itemTitle: 'Notifications',
      badgeMessage: 2,
    },
    {
      itemLink: 'messages',
      itemIcon: <MessageIcon />,
      itemTitle: 'Messages',
      badgeMessage: 1,
    },
    {
      itemLink: 'user-settings',
      itemIcon: <ManageAccountsIcon />,
      itemTitle: 'User Settings',
    },
    {
      itemLink: 'billing',
      itemIcon: <AttachMoneyIcon />,
      itemTitle: 'Billing',
    },
  ];

  return (
    <>
      <UserProfileCard />
      <div
        className={`${classes.gpa__userAccount_setting_wrapper} ${
          darkMode ? classes.gpa__dark_mode : ''
        }`}
      >
        <nav>
          <ul>
            {menuItems.map((item) => (
              <UserMenuItem key={item.itemLink} {...item} onClick={onMenuClick} />
            ))}
          </ul>
        </nav>
        <LogOutButton />
      </div>
    </>
  );
};
export default DropdownMenu;

/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

function getPrefColorScheme() {
  if (!window.matchMedia) return false;
  return window.matchMedia('(prefers-color-scheme: dark)').matches;
}

const initialMode = {
  darkMode: getPrefColorScheme(),
  isLoggedIn: false,
  open: false,
};
const themeSlice = createSlice({
  name: 'themeMode',
  initialState: initialMode,
  reducers: {
    getInitialMode(state) {
      const userMode = localStorage.getItem('dark-mode');
      const isDarkMode = userMode === 'true';
      state.darkMode = isDarkMode;
    },
    toggleMode(state, { payload }) {
      state.darkMode = payload;
      localStorage.setItem('dark-mode', payload);
    },
    loggin_user(state) {
      state.isLoggedIn = !state.isLoggedIn;
    },
    openComponentsHandler(state) {
      state.open = !state.open;
    },
  },
});

const { reducer, actions } = themeSlice;

export const { getInitialMode, toggleMode, loggin_user, openComponentsHandler } = actions;

export default reducer;

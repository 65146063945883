import React from 'react';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { CssBaseline } from '@mui/material';

const GTheme = (props) => {
  const DarkMode = useSelector((state) => state.theme.darkMode);
  const ThemeColors = {
    primary: '#0bdacf',
    secondary: '#5BC787',
  };
  const theme = createTheme({
    palette: {
      mode: `${DarkMode ? 'dark' : 'light'}`,
      background: {
        default: `${DarkMode ? '#424242' : '#fff'}`,
        paper: `${DarkMode ? '#424242' : '#fff'}`,
      },
      primary: {
        main: ThemeColors.primary,
        dark: '#01bbba',
        contrastText: '#fff',
      },
      secondary: {
        main: ThemeColors.secondary,
        contrastText: '#fff',
      },
      error: {
        main: '#d32f2f',
        dark: '#ff5722',
      },
    },
  });
  const { children } = props;
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
export default GTheme;

import React from 'react';
//= ==MUI IMPORTS===
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SchoolIcon from '@mui/icons-material/SchoolOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import CastForEducationOutlinedIcon from '@mui/icons-material/CastForEducationOutlined';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import CalendarViewWeekOutlinedIcon from '@mui/icons-material/CalendarViewWeekOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import NewUnivIcon from '@mui/icons-material/AccountBalance';
import TimeTableIcon from '@mui/icons-material/Apps';
import ForumIcon from '@mui/icons-material/ForumOutlined';
import CalenderIcon from '@mui/icons-material/DateRangeOutlined';
import ViewListIcon from '@mui/icons-material/ViewList';
import ProgressIcon from '@mui/icons-material/AutorenewOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import { NewUserIcon, VolunteerIcon } from 'containers/Icons/Icons';

export const userMenuItems = [
  {
    icon: <SchoolIcon />,
    url: 'my-course-units',
    title: 'My Course Units',
  },
  {
    icon: <PdfIcon />,
    url: 'my-documents',
    title: 'My documents',
  },
  {
    icon: <NewUnivIcon />,
    url: 'universities-events',
    title: 'universities events',
  },

  {
    icon: <TimeTableIcon />,
    url: 'time-tables',
    title: 'Time Tables',
  },
  {
    icon: <CalenderIcon />,
    url: 'reading-calender',
    title: 'Reading Calender',
  },
  {
    icon: <ProgressIcon />,
    url: 'cgpa-progress-monitor',
    title: 'Progress Monitor',
  },
  {
    icon: <ForumIcon />,
    url: 'discussion-room',
    title: 'Discussion Room',
    count: 30,
  },
  {
    icon: <QuizOutlinedIcon />,
    url: 'gpa-quiz',
    title: 'Gpa Quiz',
    count: 1,
  },
  {
    icon: <CastForEducationOutlinedIcon />,
    url: 'my-private-tutors',
    title: 'My Tutors',
  },
  {
    icon: <VolunteerIcon />,
    url: 'volunteer',
    title: 'Volunteer',
  },
  {
    icon: <AttachMoneyIcon />,
    url: 'billing',
    title: 'Billing',
    beta: true,
  },
];

export const coordinatorsMenu = [
  {
    icon: <NewUnivIcon />,
    url: 'manage-universities',
    title: 'manage universities',
  },
  {
    icon: <ViewListIcon />,
    url: 'manage-courses',
    title: 'manage courses',
  },
  {
    icon: <TimeTableIcon />,
    url: 'manage-course-units',
    title: 'Manage Course Units',
  },
  {
    icon: <PdfIcon />,
    url: 'manage-documents',
    title: 'Manage documents',
  },
  {
    icon: <TimeTableIcon />,
    url: 'manage-time-tables',
    title: 'Manage Time Tables',
  },
];

export const courseCoordinatorsMenu = [
  {
    icon: <ViewListIcon />,
    url: 'manage-courses',
    title: 'manage courses',
  },
  {
    icon: <TimeTableIcon />,
    url: 'manage-course-units',
    title: 'Manage Course Units',
  },
  {
    icon: <PdfIcon />,
    url: 'manage-documents',
    title: 'Manage documents',
  },
];

export const courseUnitCoordinatorsMenu = [
  {
    icon: <TimeTableIcon />,
    url: 'manage-course-units',
    title: 'Manage Course Units',
  },
  {
    icon: <PdfIcon />,
    url: 'manage-documents',
    title: 'Manage documents',
  },
];
export const contentCoordinatorsMenu = [
  {
    icon: <PdfIcon />,
    url: 'manage-documents',
    title: 'Manage documents',
  },
];

export const ICEditorMenu = [
  {
    icon: <CalendarViewWeekOutlinedIcon />,
    url: 'manage-inspirational-corner',
    title: 'Manage Inspirational Corner',
  },
];

export const tutorMenu = [
  ...contentCoordinatorsMenu,
  {
    icon: <PeopleOutlineIcon />,
    url: 'manage-students',
    title: 'Manage Students',
  },
  {
    icon: <AttachMoneyIcon />,
    url: 'manage-revenue',
    title: 'Manage Revenue',
  },
];

export const adminMenuItems = [
  ...coordinatorsMenu,
  {
    icon: <ForumIcon />,
    url: 'manage-discussion-room',
    title: 'Manage Discussion Room',
  },
  {
    icon: <ForumIcon />,
    url: 'manage-notifications',
    title: 'Manage Notifications',
  },
  {
    icon: <AdminPanelSettingsOutlinedIcon />,
    url: 'admin-help-center',
    title: 'Admin Help Center',
  },
];

export const superAdminItems = [
  ...adminMenuItems,
  {
    icon: <NewUserIcon />,
    url: 'manage-users',
    title: 'manage users',
  },
  {
    icon: <SettingsOutlinedIcon />,
    url: 'app-settings',
    title: 'App Settings',
  },
];

import React from 'react';
import { useSelector } from 'react-redux';
import { userRoles } from 'constants';
import DashboardIcon from '@mui/icons-material/Speed';
import { ManageAccountsIcon } from 'containers/Icons/Icons';
import DashBoardMenuItem from 'components/SideDrawer/SideDrawerContent/DashBoardMenuItem';
import Wrapper from 'containers/hoc/wrapper';
import {
  ICEditorMenu,
  adminMenuItems,
  contentCoordinatorsMenu,
  coordinatorsMenu,
  courseCoordinatorsMenu,
  courseUnitCoordinatorsMenu,
  superAdminItems,
  tutorMenu,
  userMenuItems,
} from '../MenuItems';

const UserMenu = ({ onClose }) => {
  const user = useSelector((state) => state.auth.user);
  const notifications = useSelector((state) => state.chat.notifications);
  const isCoordinator = user?.roles?.includes(userRoles.COORDINATOR.toLowerCase());
  const isStudent = user?.roles?.includes(userRoles.STUDENT.toLowerCase());
  const isAdmin = user?.roles?.includes(userRoles.ADMIN.toLowerCase());
  const isSuperAdmin = user?.roles?.includes(userRoles.SUPER_ADMIN.toLowerCase());
  const isCourseUnitCoordinator = user?.roles?.includes(
    userRoles.COURSE_UNIT_COORDINATOR.toLowerCase(),
  );
  const isCourseCoordinator = user?.roles?.includes(userRoles.COURSE_COORDINATOR.toLowerCase());
  const isContentCoordinator = user?.roles?.includes(userRoles.CONTENT_COORDINATOR.toLowerCase());
  const isICEditor = user?.roles?.includes(userRoles.INSPIRATIONAL_MANAGER.toLowerCase());
  const isUniversityCoordinator = user?.roles?.includes(
    userRoles.UNIVERSITY_COORDINATOR.toLowerCase(),
  );
  const isLecturer = user?.roles?.includes(userRoles.LECTURER.toLowerCase());
  const isTutor = user?.roles?.includes(userRoles.TUTOR.toLowerCase());

  const handleMenuClick = () => {
    if (!onClose) return;
    onClose();
  };

  return (
    <Wrapper>
      <DashBoardMenuItem
        menuItemIcon={<DashboardIcon />}
        menuItemLink='user'
        menuItemTitle='Dashboard'
        onClose={handleMenuClick}
      />
      {isStudent && (
        <>
          {userMenuItems.map((menu, index) => (
            <DashBoardMenuItem
              key={index}
              menuItemIcon={menu.icon}
              menuItemLink={menu.url}
              menuItemTitle={menu.title}
              beta={menu?.beta}
              menuCount={menu.url === 'discussion-room' ? notifications.length : null}
              onClose={handleMenuClick}
            />
          ))}
        </>
      )}

      {isCoordinator && !isAdmin && (
        <>
          {coordinatorsMenu.map((menu, index) => (
            <DashBoardMenuItem
              key={index}
              menuItemIcon={menu.icon}
              menuItemLink={menu.url}
              menuItemTitle={menu.title}
              beta={menu?.beta}
              menuCount={menu.url === 'discussion-room' ? notifications.length : null}
              onClose={handleMenuClick}
            />
          ))}
        </>
      )}

      {isCourseUnitCoordinator && !isCourseCoordinator && (
        <>
          {courseUnitCoordinatorsMenu.map((menu, index) => (
            <DashBoardMenuItem
              key={index}
              menuItemIcon={menu.icon}
              menuItemLink={menu.url}
              menuItemTitle={menu.title}
              beta={menu?.beta}
              menuCount={menu.url === 'discussion-room' ? notifications.length : null}
              onClose={handleMenuClick}
            />
          ))}
        </>
      )}

      {isCourseCoordinator && !isUniversityCoordinator && (
        <>
          {courseCoordinatorsMenu.map((menu, index) => (
            <DashBoardMenuItem
              key={index}
              menuItemIcon={menu.icon}
              menuItemLink={menu.url}
              menuItemTitle={menu.title}
              beta={menu?.beta}
              menuCount={menu.url === 'discussion-room' ? notifications.length : null}
              onClose={handleMenuClick}
            />
          ))}
        </>
      )}
      {isUniversityCoordinator && !isAdmin && (
        <>
          {coordinatorsMenu.map((menu, index) => (
            <DashBoardMenuItem
              key={index}
              menuItemIcon={menu.icon}
              menuItemLink={menu.url}
              menuItemTitle={menu.title}
              beta={menu?.beta}
              menuCount={menu.url === 'discussion-room' ? notifications.length : null}
              onClose={handleMenuClick}
            />
          ))}
        </>
      )}

      {isContentCoordinator &&
        !isUniversityCoordinator &&
        !isCoordinator &&
        !isCourseCoordinator &&
        !isCourseUnitCoordinator &&
        !isAdmin && (
          <>
            {contentCoordinatorsMenu.map((menu, index) => (
              <DashBoardMenuItem
                key={index}
                menuItemIcon={menu.icon}
                menuItemLink={menu.url}
                menuItemTitle={menu.title}
                beta={menu?.beta}
                menuCount={menu.url === 'discussion-room' ? notifications.length : null}
                onClose={handleMenuClick}
              />
            ))}
          </>
        )}

      {isAdmin && (
        <>
          {adminMenuItems.map((menu, index) => (
            <DashBoardMenuItem
              key={index}
              menuItemIcon={menu.icon}
              menuItemLink={menu.url}
              menuItemTitle={menu.title}
              beta={menu?.beta}
              menuCount={menu.url === 'discussion-room' ? notifications.length : null}
              onClose={handleMenuClick}
            />
          ))}
        </>
      )}

      {isICEditor && (
        <>
          {ICEditorMenu.map((menu, index) => (
            <DashBoardMenuItem
              key={index}
              menuItemIcon={menu.icon}
              menuItemLink={menu.url}
              menuItemTitle={menu.title}
              beta={menu?.beta}
              menuCount={menu.url === 'discussion-room' ? notifications.length : null}
              onClose={handleMenuClick}
            />
          ))}
        </>
      )}
      {(isLecturer || isTutor) && (
        <>
          {tutorMenu.map((menu, index) => (
            <DashBoardMenuItem
              key={index}
              menuItemIcon={menu.icon}
              menuItemLink={menu.url}
              menuItemTitle={menu.title}
              beta={menu?.beta}
              menuCount={menu.url === 'discussion-room' ? notifications.length : null}
              onClose={handleMenuClick}
            />
          ))}
        </>
      )}
      {isSuperAdmin && (
        <>
          {superAdminItems.map((menu, index) => (
            <DashBoardMenuItem
              key={index}
              menuItemIcon={menu.icon}
              menuItemLink={menu.url}
              menuItemTitle={menu.title}
              beta={menu?.beta}
              menuCount={menu.url === 'discussion-room' ? notifications.length : null}
              onClose={handleMenuClick}
            />
          ))}
        </>
      )}
      <DashBoardMenuItem
        menuItemIcon={<ManageAccountsIcon />}
        menuItemLink='user-settings'
        menuItemTitle='User Settings'
        onClose={handleMenuClick}
        isNew
      />
    </Wrapper>
  );
};

export default UserMenu;

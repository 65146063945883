import React from 'react';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { CURRENT_APP_VERSION } from 'constants';
import classes from './CopyRight.module.css';

const CopyRight = (props) => {
  const getCurrentYear = new Date().getFullYear();
  const darkMode = useSelector((state) => state.theme.darkMode);
  const { className } = props;
  return (
    <>
      <div className='flex justify-center items-center text-center pt-3 '>
        <div
          className={`${
            darkMode ? 'bg-[#424242] shadow-md text-gray-300 ' : 'bg-[#fff] text-[#000]/70'
          } px-3 rounded-full text-sm`}
        >
          v{CURRENT_APP_VERSION}
        </div>
      </div>
      <Typography className={`${classes.gpa__mobile_footer_copyright_text} ${className}`}>
        © 2019 - {getCurrentYear} All Rights Reserved by Gpa Elevator
      </Typography>
    </>
  );
};

export default CopyRight;

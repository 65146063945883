const AuthToken = localStorage.getItem('AuthToken');
const tokenObj = JSON.parse(AuthToken);
const token = tokenObj?.token;

// AUTH ROUTES
export const loginRoute = (isSuperAdmin) => ({
  url: isSuperAdmin ? `/users/adminLogin` : `/users/login`,
});
export const extendSessionRoute = () => ({
  url: `/users/extendSession`,
});
export const signupRoute = () => ({
  url: `/users/signup`,
});

export const forgotPasswordRoute = () => ({
  url: `/users/forgotPassword`,
});
export const passwordResetRoute = (resetToken) => ({
  url: `/users/resetPassword/${resetToken}`,
});

export const requestAccountVerificationRoute = () => ({
  url: `/users/requestAccountVerification`,
});
export const accountVerificationRoute = (verificationToken) => ({
  url: `/users/verifyAccount/${verificationToken}`,
});

// DOCUMENT ROUTES
export const documentUploadRoute = () => ({
  url: `/files/upload`,
  config: {
    headers: { 'Content-Type': 'multipart/form-data' },
  },
  token,
});
export const getDocumentsRoute = () => ({
  url: `/files/getAll`,
});
export const getCUDocumentsRoute = (course_unit_id) => ({
  url: `/files/${course_unit_id}`,
});
export const getUserDocumentsRoute = () => ({
  url: `/files/getUserDocuments`,
});
export const getDocumentRoute = (document_id) => ({
  url: `/files/getDocumentDetails/${document_id}`,
});
export const deleteDocumentRoute = (document_id) => ({
  url: `/files/delete/${document_id}`,
});
export const updateViewCountRoute = (document_id) => ({
  url: `/files/updateViewCount/${document_id}`,
});

// CHAT ROUTES
export const createNewChatRoute = () => ({
  url: `/chats`,
});

export const getUserChatsRoute = () => ({
  url: `/chats`,
});
export const getChatDetailsRoute = (chatId) => ({
  url: `/chats/${chatId}`,
});

export const getChatMessagesRoute = (chatId) => ({
  url: `/messages/${chatId}`,
});

export const createChatMessageRoute = () => ({
  url: `/messages`,
});
export const createDiscussionRoomRoute = () => ({
  url: `/chats/droom`,
});
export const editDiscussionRoomRoute = () => ({
  url: `api/v1/chats/droom/users`,
});

export const searchUsersRoute = (searchQ) => ({
  url: `/users/search?q=${searchQ}`,
});
export const updateUserRolesRoute = () => ({
  url: `/users/updateUserRole`,
});

export const searchContentRoute = (searchQ) => ({
  url: `/search?q=${encodeURIComponent(searchQ)}`,
});

export const searchCourseUnitRoute = (searchQ) => ({
  url: `/search/course-units?q=${encodeURIComponent(searchQ)}`,
});
export const searchDocumentsRoute = (searchQ) => ({
  url: `/search/documents?q=${encodeURIComponent(searchQ)}`,
});

export const searchCoursesByUniversityIdRoute = (searchQ) => ({
  url: `/search/university-courses?q=${encodeURIComponent(searchQ)}`,
});

/**
 * @name getCoursesByUniversityIdRoute
 * @description gets the route for getting courses by university id
 * @param {string} university_id
 * @returns  {object} {url}
 */
export const getCoursesByUniversityIdRoute = (university_id) => ({
  url: `courses/getCoursesByUniversity/${university_id}`,
});

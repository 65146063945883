/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
};
const deleteDocumentSlice = createSlice({
  name: 'deleteDocument',
  initialState,
  reducers: {
    deleteDocumentLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
  },
});

const { reducer, actions } = deleteDocumentSlice;

export const { deleteDocumentLoading } = actions;
export default reducer;

import React from 'react';
import { useSelector } from 'react-redux';

//= ==MUI IMPORTS===
import { Box, useScrollTrigger, Fab, Zoom } from '@mui/material';
import BackTopIcon from '@mui/icons-material/DoubleArrow';

//= ==COMPONENT IMPORTS===
import classes from './BackToTop.module.css';

const ScrollTop = (props) => {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#gpa__back_to_top_anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box onClick={handleClick} sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 30 }}>
        {children}
      </Box>
    </Zoom>
  );
};

const BackToTop = (props) => {
  const DarkMode = useSelector((state) => state.theme.darkMode);
  return (
    <ScrollTop {...props}>
      <Fab
        className={`${classes.gpa__back_to_top} ${DarkMode ? classes.gpa__dark_mode : ''}`}
        aria-label='scroll back to top'
      >
        <BackTopIcon className={classes.gpa__back_to_top_icon} />
      </Fab>
    </ScrollTop>
  );
};
export default BackToTop;

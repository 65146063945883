import React, { useEffect, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router } from 'react-router-dom';

import { Worker } from '@react-pdf-viewer/core';
//= ==MUI IMPORTS===
import { Paper } from '@mui/material';

//= ==REDUX STORE IMPORTS===
import { useDispatch, useSelector } from 'react-redux';
import RequestInterceptor from 'adapters/axios';
import AppRoutes from './Routes';

//= ==COMPONENT IMPORTS===
import NewMemberPrompt from './Membership/UserRegistration/NewMemberPrompt';
import { getInitialMode } from '../store/Slices/themeSlice';
import { AutoAuthenticate } from '../store/Actions/AuthActions';

import AppBar from './AppBar/AppBar';
import GTheme from './UI/Theme/Theme';
import BackToTop from './BackToTop/BackToTop';
import Spinner from './UI/Spinner/Spinner';
import { handleMenuPopup, setShowTimeUpPrompt } from '../store/Slices/uiSlice';
import TimeUpPrompt from '../utils/TimeUpPrompt';
import ChatModal from './DashBoard/DashBoardMenuItems/UserMenuPanels/DiscussionRoom/ChatComponents/ChatModal';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

const App = () => {
  RequestInterceptor();
  const darkMode = useSelector((state) => state.theme.darkMode);
  const showPrompt = useSelector((state) => state.ui.showTimeUpPrompt);
  const isAuthenticated = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getInitialMode());
    AutoAuthenticate(dispatch);
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <GTheme>
      <Helmet>
        <meta name='theme-color' content={darkMode ? '#1c1d1f' : '#0bdacf'} />
      </Helmet>

      <Router>
        <ToastContainer
          position='top-center'
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme={darkMode ? 'dark' : 'light'}
        />
        <ChatModal
          open={showPrompt}
          handleClose={() => dispatch(setShowTimeUpPrompt(false))}
          component={<TimeUpPrompt />}
          modalTitle='Extend Session Time'
        />

        <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'>
          <Paper
            elevation={0}
            square
            className={darkMode ? 'gpa__bg_dark_mode' : 'gpa__bg_light_mode'}
            onClick={() => dispatch(handleMenuPopup(false))}
          >
            <AppBar />
            <Suspense
              fallback={
                <div className='flex items-center h-screen'>
                  <Spinner />
                </div>
              }
            >
              <AppRoutes />
            </Suspense>
            <BackToTop />
          </Paper>
        </Worker>
        {!isAuthenticated && <NewMemberPrompt />}
      </Router>
      {/* {isAuthenticated && <Notification />} */}
    </GTheme>
  );
};

export default App;

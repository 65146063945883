import React from "react";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import RoomIcon from "@mui/icons-material/Room";
import classes from "./ContactSection.module.css";

const ContactSection = () => {
  const DarkMode = useSelector((state) => state.theme.darkMode);

  return (
    <div
      className={`${classes.gpa__contacts_section_wrapper} ${
        DarkMode ? classes.gpa_dark_mode : ""
      }`}
    >
      <p>
        <RoomIcon /> Find Us at Ham Towers level 3 Opp. Makerere University Main
        Gate
      </p>
      <p>
        <EmailIcon />{" "}
        <a href="mailto:info@gpaelevator.com">info@gpaelevator.com</a>{" "}
      </p>
      <Typography variant="h6">
        <WhatsAppIcon />{" "}
        <a
          href="https://wa.link/iyj0rg"
          target="_blank"
          rel="noopener noreferrer"
        >
          0759130054
        </a>{" "}
      </Typography>
      <Typography variant="h6">
        <LocalPhoneIcon /> <a href="tel:+256778451024">0778451024</a>{" "}
      </Typography>
    </div>
  );
};

export default ContactSection;

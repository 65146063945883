import axios from 'axios';
import { showAlert } from 'components/Common';
import { VIDEO_TYPES, YOUTUBE_API_KEY } from 'constants';
import { useState, useEffect } from 'react';
import { getVideoId } from 'utils';

const useVideoDetails = (videoUrl, isEdit) => {
  const [videoDetails, setVideoDetails] = useState({});
  const [loading, setLoading] = useState(false);

  const getYoutubeVideoDetails = async () => {
    if (isEdit || !videoUrl) return;
    try {
      const videoId = getVideoId(videoUrl, VIDEO_TYPES.YOUTUBE);
      setLoading(true);
      const response = await axios({
        method: 'GET',
        url: `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${YOUTUBE_API_KEY}&part=snippet,contentDetails,status`,
      });
      setVideoDetails(response.data);
    } catch (err) {
      showAlert({
        variant: 'error',
        message: 'Failed to get video details, Please add them manually',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getYoutubeVideoDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoUrl]);

  return {
    videoDetails,
    loading,
  };
};

export default useVideoDetails;

const { GPA_APIS } = require('adapters');
const { useState, useEffect } = require('react');

const useAppSettings = () => {
  const [settings, setSettings] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    const res = await GPA_APIS.auth.get.getAppSettings();
    setSettings(res.data.appSettings);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { settings, loading };
};

export default useAppSettings;

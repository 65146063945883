import React from 'react';

//= ==REDUX IMPORTS===
import { useSelector } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';

//= ==REACT BOOTSTRAP IMPORTS===

//= ==COMPONENT IMPORTS===
import classes from './SearchSection.module.css';
import Search from '../../UI/Search/Search';

const SearchSection = () => {
  const DarkMode = useSelector((state) => state.theme.darkMode);

  return (
    <Container
      fluid
      className={`${classes.gpa__home_search_section_wrapper} ${
        DarkMode ? classes.gpa__dark_mode : ''
      }`}
    >
      <Row className='text-center'>
        <Col md={{ span: 8, offset: 2 }} className='text-center'>
          <h2 className={`${classes.gpa__home_search_section_title} gpa__text `}>
            Search gpa elevator
          </h2>
          <h4 className={`${classes.gpa__home_search_section_subtitle} gpa__text`}>
            Type the name of your university, Course or Course Unit to get access to all documents
            and start reading...
          </h4>
          <Search placeholder='Type to start searching...' />
        </Col>
      </Row>
    </Container>
  );
};
export default SearchSection;

import { useEffect, useState } from 'react';
import { GPA_APIS } from 'adapters';

let data = {};

const useUserSettings = (refresh) => {
  const [userSettings, setUserSettings] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchUserSettings = async () => {
    if (Object.keys(data).length > 0 && !refresh) {
      setUserSettings(data);
      return;
    }
    setLoading(true);
    try {
      const res = await GPA_APIS.auth.get.getUserSettings();
      if (res.status === 200) {
        setUserSettings(res.data.settings);
        data = res.data.settings;
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserSettings();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return {
    userSettings,
    loading,
  };
};

export default useUserSettings;

import { loginRoute, signupRoute, updateUserRolesRoute } from 'adapters/routes';

/**
 * @name authRequests
 * @description Function used to make request to the auth endpoints
 * @param requestor
 * @returns {object} authRequests
 */
export const authRequests = (requestor) => ({
  /**
   * @name register
   * @description Function used to make request to the register user
   * @param {object} data
   * @returns {Promise<any>} response
   */
  register: async (data) => {
    const { url } = signupRoute();
    const response = await requestor({
      method: 'POST',
      url,
      data,
    });
    return response;
  },

  /**
   * @name login
   * @description Function used to make request to login user
   * @param {object} data
   * @param {boolean} isSuperAdmin
   * @returns {Promise<any>} response
   */
  login: async (data, isSuperAdmin) => {
    const { url } = loginRoute(isSuperAdmin);
    const response = await requestor({
      method: 'POST',
      url,
      data,
    });
    return response;
  },

  /**
   * @name updateUserRoles
   * @description Function used to make request to update user roles
   * @param {object} data
   * @returns {Promise<any>} response
   */
  updateUserRoles: async (data) => {
    const { url } = updateUserRolesRoute();
    const response = await requestor({
      method: 'POST',
      url,
      data,
    });
    return response;
  },

  /**
   * @name updateUserAccessPoints
   * @description Function used to make request to update user roles
   * @param {object} data
   * @returns {Promise<any>} response
   */
  updateUserAccessPoints: async (data) => {
    const url = `/users/updateUserAccessPoints`;
    const response = await requestor({
      method: 'PATCH',
      url,
      data,
    });
    return response;
  },

  /**
   * @name updateUserNotificationDevices
   * @description Function used to make request to update user roles
   * @param {object} data
   * @returns {Promise<any>} response
   */
  updateUserNotificationDevices: async (data) => {
    const url = `/users/updateNotificationIds`;
    const response = await requestor({
      method: 'PATCH',
      url,
      data,
    });
    return response;
  },

  /**
   * @name buyAccessPoints
   * @description Function used to make request to update user roles
   * @param {object} data
   * @returns {Promise<any>} response
   */
  buyAccessPoints: async (data) => {
    const url = `/users/billing/buyAccessPoints`;
    const response = await requestor({
      method: 'POST',
      url,
      data,
    });
    return response;
  },

  /**
   * @name forgotPassword
   * @description Function used to make request to reset user password
   * @param {string} email
   * @returns {Promise<any>} response
   */
  forgotPassword: async (email) => {
    const url = `/users/forgotPassword`;
    const response = await requestor({
      method: 'POST',
      url,
      data: { email },
    });
    return response;
  },

  /**
   * @name resetPassword
   * @description Function used to make request to reset user password
   * @param {string} token
   * @param {string} password
   * @returns {Promise<any>} response
   */
  resetPassword: async (password, token) => {
    const url = `/users/resetPassword/${token}`;
    const response = await requestor({
      method: 'PATCH',
      url,
      data: { password },
    });
    return response;
  },

  /**
   * @name updateUserInformation
   * @description Function used to make request to update user information
   * @param {object} data
   * @returns {Promise<any>} response
   */
  updateUserInformation: async (data) => {
    const url = `/users/updateMe`;
    const response = await requestor({
      method: 'PATCH',
      url,
      data,
    });
    return response;
  },

  /**
   * @name updateUserSettings
   * @description Function used to make request to update user Settings
   * @param {object} data
   * @returns {Promise<any>} response
   */
  updateUserSettings: async (data) => {
    const url = `/users/settings/updateUserSettings`;
    const response = await requestor({
      method: 'PATCH',
      url,
      data,
    });
    return response;
  },

  /**
   * @name updateUserImage
   * @description Function used to make request to update user image
   * @param {object} data
   * @returns {Promise<any>} response
   */
  updateUserImage: async (data) => {
    const url = `/users/updateProfileImage`;
    const formData = new FormData();
    formData.append('file', data.image);

    const response = await requestor({
      method: 'PATCH',
      url,
      data: formData,
    });
    return response;
  },

  /**
   * @name requestPrimaryEmailVerificationLink
   * @description Function used to make request to primary email verification link
   * @param {string} email
   * @returns {Promise<any>} response
   */
  requestPrimaryEmailVerificationLink: async (email) => {
    const url = `/users/requestAccountVerification`;
    const response = await requestor({
      method: 'POST',
      url,
      data: { email },
    });
    return response;
  },

  /**
   * @name requestPrimaryEmailChange
   * @description Function used to make request to update user primary email
   * @param {object} data { email: string, newEmail: string }
   * @returns {Promise<any>} response
   */
  requestPrimaryEmailChange: async (data) => {
    const url = `/users/requestPrimaryEmailChange`;
    const response = await requestor({
      method: 'POST',
      url,
      data,
    });
    return response;
  },

  /**
   * @name requestEmailToken
   * @description Function used to make request to update user image
   * @param {string} email
   * @returns {Promise<any>} response
   */
  requestEmailToken: async (email) => {
    const url = `/users/settings/requestVerification/email`;
    const response = await requestor({
      method: 'POST',
      url,
      data: { email },
    });
    return response;
  },

  /**
   * @name verifyEmailToken
   * @description Function used to make request to update user image
   * @param {object} data
   * @returns {Promise<any>} response
   */
  verifyEmailToken: async (data) => {
    const url = `/users/settings/verifyEmail`;
    const response = await requestor({
      method: 'POST',
      url,
      data,
    });
    return response;
  },

  /**
   * @name deleteUserAccount
   * @description Function used to make request to delete user account
   * @param {object} email
   * @returns {Promise<any>} response
   */
  deleteUserAccount: async (email) => {
    const url = `/users/deleteUserAccount`;
    const response = await requestor({
      method: 'DELETE',
      url,
      data: { email },
    });
    return response;
  },

  get: {
    /**
     * @name removeProfileImage
     * @description Function used to make request to get document access status
     * @returns {Promise<any>} response
     */
    removeProfileImage: async () => {
      const url = `/users/removeProfileImage`;
      const response = await requestor({
        method: 'GET',
        url,
      });
      return response;
    },
    /**
     * @name getUserSettings
     * @description Function used to make request to get user settings
     * @returns {Promise<any>} response
     */
    getUserSettings: async () => {
      const url = `/users/settings/getUserSettings`;
      const response = await requestor({
        method: 'GET',
        url,
      });
      return response;
    },

    /**
     * @name getAppSettings
     * @description Function used to make request to get app settings
     * @returns {Promise<any>} response
     */
    getAppSettings: async () => {
      const url = `/users/settings/getAppSettings`;
      const response = await requestor({
        method: 'GET',
        url,
      });
      return response;
    },

    /**
     * @name getUserTransactions
     * @description Function used to make request to get user transactions
     * @returns {Promise<any>} response
     */
    getUserTransactions: async () => {
      const url = `/users/billing/getUserTransactions`;
      const response = await requestor({
        method: 'GET',
        url,
      });
      return response;
    },

    /**
     * @name checkPaymentStatus
     * @description Function used to check payment status of a transaction reference
     * @param {string} tx_ref
     * @returns {Promise<any>} response
     */
    checkPaymentStatus: async (tx_ref) => {
      const url = `/users/billing/checkPaymentStatus?tx_ref=${tx_ref}`;
      const response = await requestor({
        method: 'GET',
        url,
      });
      return response;
    },

    /**
     * @name getUserAccessPoints
     * @description Function used to make request to get document access status
     * @returns {Promise<any>} response
     */
    getUserAccessPoints: async () => {
      const url = `/users/getUserAccessPoints`;
      const response = await requestor({
        method: 'GET',
        url,
      });
      return response;
    },

    /**
     * @name documentAccessStatus
     * @description Function used to make request to get document access status
     * @returns {Promise<any>} response
     */
    documentAccessStatus: async (documentId, documentCategory) => {
      const url = `/users/documentAccessPermission/${documentId}?categoryAccessed=${documentCategory}`;
      const response = await requestor({
        method: 'GET',
        url,
      });
      return response;
    },

    /**
     * @name checkAuthorization
     * @description Function used to get all documents uploaded by a user
     * @returns {object} response
     */
    checkAuthorization: async (documentId) => {
      try {
        const url = `/users/checkAuthorization/${documentId}`;
        const response = await requestor({
          method: 'GET',
          url,
        });
        if (response.data.hasAccess) {
          return true;
        }
        return false;
      } catch (error) {
        return false;
      }
    },

    /**
     * @name getStudentStatus
     * @description Function used to get the students status
     * @returns {object} response
     */
    getStudentStatus: async () => {
      const url = `/users/checkStudentStatus`;
      const response = await requestor({
        method: 'GET',
        url,
      });

      return response;
    },

    /**
     * @name getUnverifiedUsers
     * @description Function used to get the students status
     * @param {string} startDate
     * @returns {object} response
     */
    getUnverifiedUsers: async (startDate) => {
      const url = `/users/getUnverifiedUsers?startDate=${startDate}`;
      const response = await requestor({
        method: 'GET',
        url,
      });

      return response;
    },
  },
});

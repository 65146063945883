import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

// ===MUI IMPORTS===
import { Avatar, Paper } from '@mui/material';

// ===BOOTSTRAP IMPORTS===
import { Col } from 'react-bootstrap';

// ===COMPONENT IMPORTS===
import classes from './UniversityCard.module.css';

const UniversityCard = (props) => {
  const DarkMode = useSelector((state) => state.theme.darkMode);
  const { universityLink, universityLogo, universityName } = props;
  return (
    <Link to={`/${universityLink}`}>
      <Paper
        className={`${classes.gpa__university_card_wrapper_paper} ${
          DarkMode ? classes.gpa__dark_mode : ''
        }`}
        elevation={2}
      >
        <Col lg={3} md={3} sm={3} xs={3} className={classes.gpa__university_card_univ_logo_wrapper}>
          <Avatar
            variant='square'
            src={universityLogo}
            alt={universityName}
            style={{ textTransform: 'uppercase' }}
          >
            {universityName.charAt(0)}
          </Avatar>
        </Col>
        <Col
          lg={9}
          md={9}
          sm={9}
          xs={9}
          className={classes.gpa__university_card_univ_title_wrapper}
        >
          <h5> {universityName}</h5>
        </Col>
      </Paper>
    </Link>
  );
};
export default UniversityCard;

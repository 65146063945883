import React from 'react';
import { useSelector } from 'react-redux';
import { Avatar } from '@mui/material';

// ===COMPONENT IMPORT===
import LogoutButton from '../../Membership/LogoutButton/LogoutButton';
import LoginButton from '../../Membership/LoginButton/LoginButton';
import RegisterButton from '../../Membership/RegisterButton/RegisterButton';
// import CopyRIght from '../../Footer/CopyRight/CopyRight';
import classes from './MobileDrawerFooter.module.css';
import UserName from '../../AuthenticatedUser/UserProfileCard/UserName';

const MobileDrawerFooter = (props) => {
  const DarkMode = useSelector((state) => state.theme.darkMode);
  const CurrentUser = useSelector((state) => state.auth.user);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const { DrawerClose } = props;
  return (
    <div
      className={`${classes.gpa__mobile_drawer_footer_wrapper} ${
        DarkMode ? classes.gpa__dark_mode : ''
      }`}
    >
      {isLoggedIn ? (
        <div
          className={`${classes.gpa__mobile_drawer_footer_inner_wrapper} ${
            DarkMode ? classes.gpa__dark_mode : ''
          }`}
        >
          <div className={classes.gpa__mobile_drawer_footer_content_wrapper}>
            <Avatar src={CurrentUser.image} alt={CurrentUser.first_name}>
              <UserName />{' '}
            </Avatar>
            <div style={{ paddingLeft: 5, textAlign: 'left' }}>
              <h4>{`${CurrentUser.first_name} ${CurrentUser.last_name}`}</h4>
              <h5>{CurrentUser.email}</h5>
            </div>
          </div>
          <LogoutButton />
        </div>
      ) : (
        <div className={classes.gpa__mobile_drawer_footer_membership_wrapper}>
          <LoginButton DrawerClose={DrawerClose} />
          <RegisterButton DrawerClose={DrawerClose} />
        </div>
      )}
    </div>
  );
};
export default MobileDrawerFooter;

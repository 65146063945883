import React from "react";
import { Box } from "@mui/material";
import DefaultFooter from "./DefaultFooter/DefaultFooter";
import MobileFooter from "./MobileFooter/MobileFooter";

const Footer = () => (
    <>
      <Box component="" sx={{ display: { sm: "block", xs: "none" } }}>
        <DefaultFooter />
      </Box>
      <Box component="" sx={{ display: { sm: "none", xs: "block" } }}>
        <MobileFooter />
      </Box>
    </>
  );
export default Footer;

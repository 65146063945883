import React, { useState, lazy } from 'react';
// import PropTypes from 'prop-types';

//= ==REDUX IMPORTS===
import { useSelector } from 'react-redux';

//= ==MUI IMPORTS===
import { useTheme } from '@mui/material/styles';
import { Typography, Box, Tab, Tabs } from '@mui/material';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import DescriptionIcon from '@mui/icons-material/Description';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

//= ==COMPONENTS IMPORTS===
import classes from './Popular.module.css';

const PopularDocument = lazy(() => import('./PopularDocuments/PopularDocument'));
const PopularCourses = lazy(() => import('../../Courses/PopularCourses/PopularCourses'));
const CardCarousel = lazy(() => import('../../CourseUnits/CardCarousel/CardCarousel'));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      hidden={value !== index}
      id={`gpa__tab-${index}`}
      aria-labelledby={`gpa__tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const gpaProps = (index) => ({
  id: `gpa__tab-${index}`,
  'aria-controls': `gpa__tab-${index}`,
});

const PopularContent = () => {
  const DarkMode = useSelector((state) => state.theme.darkMode);
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor='secondary'
        textColor='inherit'
        variant='fullWidth'
        aria-label='All popular tabs'
        className={`${classes.gpa__popular_tabs_wrapper} ${DarkMode ? classes.gpa__dark_mode : ''}`}
      >
        <Tab
          className='gpa__popular_item_tab'
          label='Popular Courses '
          {...gpaProps(0)}
          icon={<AccountBalanceIcon className={classes.gpa__popular_item_icon} />}
        />
        <Tab label='Popular Course Units' {...gpaProps(1)} icon={<LibraryBooksIcon />} />
        <Tab label='Popular Documents' {...gpaProps(2)} icon={<DescriptionIcon />} />
      </Tabs>

      <TabPanel value={value} index={0} dir={theme.direction}>
        <PopularCourses />
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        <CardCarousel />
      </TabPanel>
      <TabPanel value={value} index={2} dir={theme.direction}>
        <PopularDocument />
      </TabPanel>
    </Box>
  );
};
export default PopularContent;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

//= ==MUI IMPORTS===
import { handleMenuPopup } from 'store/Slices/uiSlice';
import { Button } from '@mui/material';

//= ==COMPONENT IMPORTS===
import UserProfilePicture from './UserProfilePicture';
import classes from './UserProfileCard.module.css';

const UserProfileCard = () => {
  const DarkMode = useSelector((state) => state.theme.darkMode);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  return (
    <div className={classes.gpa__user_profile_card_wrapper}>
      <div
        className={`${classes.gpa__user_profile_card_inner_wrapper} ${
          DarkMode ? classes.gpa__dark_mode : ''
        }`}
      >
        <UserProfilePicture />
        <div className={classes.gpa__user_profile_card_content_wrapper}>
          <h4>{`${user.first_name} ${user.last_name}`}</h4>
          <h5>{user.email}</h5>
          <div className='text-successText'>Access Points: {user.accessPoints}</div>
          <div className='py-1'>
            <Button
              size='small'
              variant='outlined'
              color='primary'
              component={Link}
              to='/dashboard/user'
              onClick={() => dispatch(handleMenuPopup(false))}
              className='hover:!text-primary'
            >
              My Account
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserProfileCard;

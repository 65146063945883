import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { useUniversities } from 'hooks';
import { Paper, Button, Skeleton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import classes from './SideMenu.module.css';

const SideMenu = (props) => {
  const darkMode = useSelector((state) => state.theme.darkMode);
  const { universities, loading } = useUniversities();
  const [showMenu, setShowMenu] = useState(true);
  const { DrawerClose } = props;

  const closeSideBarHandler = DrawerClose;
  return (
    <>
      <div
        className={`${classes.gpa__side_menu_wrapper} ${darkMode ? classes.gpa__dark_mode : ''}`}
      >
        <Button
          className={classes.gpa__mobile_sidebar_menu_item}
          onClick={() => setShowMenu(!showMenu)}
        >
          All universities
          {showMenu ? (
            <ExpandLessIcon className={darkMode ? 'text-white' : 'text-black'} />
          ) : (
            <ExpandMoreIcon className={darkMode ? 'text-white' : 'text-black'} />
          )}
        </Button>
        {showMenu && (
          <Paper
            className={`${classes.gpa__mobile_sidebar_menu_item_list} ${
              darkMode ? classes.gpa__dark_mode : ''
            }`}
            elevation={0}
          >
            <ul>
              {loading
                ? [...Array(5).keys()].map(() => <Skeleton width='100%' animation='wave' />)
                : universities &&
                  universities.map((uni) => (
                    <Link
                      to={`/${uni.name}`}
                      onClick={closeSideBarHandler}
                      className={classes.gpa__university}
                      key={uni.id}
                    >
                      <li>{uni.name && uni.name.replace(/-/g, ' ')}</li>
                    </Link>
                  ))}
            </ul>
          </Paper>
        )}
      </div>
      <Button
        className={classes.gpa__mobile_sidebar_menu_item}
        onClick={closeSideBarHandler}
        component={Link}
        to='/our-creative-team'
      >
        Our Team
      </Button>
      <Button className={classes.gpa__mobile_sidebar_menu_item} onClick={closeSideBarHandler}>
        About Us
      </Button>
      <Button className={classes.gpa__mobile_sidebar_menu_item} onClick={closeSideBarHandler}>
        Contact Us
      </Button>
      <Button className={classes.gpa__mobile_sidebar_menu_item} onClick={closeSideBarHandler}>
        University Agents
      </Button>
    </>
  );
};

export default SideMenu;

import { axios } from './axios';
import requestAdapter from './requests';

/**
 * @name Requestor
 * @param {RequestConfig} config - Request settings.
 */

export const Requestor = async (config) => {
  const { url, data, headers } = config;
  const res = await axios(url, {
    method: 'GET',
    headers: {
      ...headers,
      Accept: 'application/json',
    },
    body: JSON.stringify(data),
    ...config,
  });
  return res;
};

const GPA_APIS = requestAdapter(Requestor);

export { GPA_APIS };

import React from 'react';
import { useSelector } from 'react-redux';

// ===MUI IMPORTS===
import { Avatar, Paper, Typography, Skeleton } from '@mui/material';

// ===BOOTSTRAP IMPORTS===
import { Col } from 'react-bootstrap';

// ===COMPONENT IMPORTS===
import classes from './UniversityCard.module.css';

const UniversityCardSkelton = () => {
  const DarkMode = useSelector((state) => state.theme.darkMode);

  return (
    <Paper
      className={`${classes.gpa__university_card_wrapper_paper} ${
        DarkMode ? classes.gpa__dark_mode : ''
      }`}
      elevation={2}
    >
      <Col lg={3} md={3} sm={3} xs={3} className={classes.gpa__university_card_univ_logo_wrapper}>
        <Skeleton variant='circle'>
          <Avatar />
        </Skeleton>
      </Col>
      <Col lg={9} md={9} sm={9} xs={9} className={classes.gpa__university_card_univ_title_wrapper}>
        <Typography variant='h4'>
          <Skeleton type='text' animation='wave' />
        </Typography>
      </Col>
    </Paper>
  );
};
export default UniversityCardSkelton;

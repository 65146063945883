import { GPA_APIS } from 'adapters';
import { useEffect, useState } from 'react';

const useCourseCourseUnits = (courseId) => {
  const [courseCourseUnits, setCourseCourseUnits] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchCourseCourseUnits = async () => {
    if (!courseId) return;
    setIsLoading(true);
    try {
      const response = await GPA_APIS.courseUnit.get.getCourseUnitsByCourseId(courseId);
      setCourseCourseUnits(response.data.courseUnits);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setCourseCourseUnits([]);
    }
  };

  useEffect(() => {
    fetchCourseCourseUnits();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId]);

  return { courseCourseUnits, isLoading };
};

export default useCourseCourseUnits;

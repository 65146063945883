import { configureStore } from '@reduxjs/toolkit';
import selectedItemsReducer from './Slices/selectedItemsSlice';
import universitiesReducer from './Slices/universitiesSlice';
import universityReducer from './Slices/universitySlice';
import themeReducer from './Slices/themeSlice';
import uiReducer from './Slices/uiSlice';
import coursesReducer from './Slices/coursesSlice';
import courseReducer from './Slices/courseSlice';
import courseUnitsReducer from './Slices/courseUnitsSlice';
import courseUnitReducer from './Slices/courseUnitSlice';
import userReducer from './Slices/userSlice';
import courseCategoryReducer from './Slices/courseCategorySlice';
import userRegistrationReducer from './Slices/userRegistrationSlice';
import newDocumentSlice from './Slices/newDocumentSlice';
import documentsSlice from './Slices/documentsSlice';
import documentSlice from './Slices/documentSlice';
import deleteDocSlice from './Slices/deleteDocumentSlice';
import enrollUserReducer from './Slices/enrollUserSlice';
import authReducer from './Slices/authSlice';
import passwordReducer from './Slices/passwordSlice';
import chatReducer from './Slices/chatSlice';

const store = configureStore({
  reducer: {
    theme: themeReducer,
    ui: uiReducer,
    auth: authReducer,
    chat: chatReducer,
    password: passwordReducer,
    enrollUser: enrollUserReducer,

    universities: universitiesReducer,
    university: universityReducer,

    courses: coursesReducer,
    course: courseReducer,
    courseCategories: courseCategoryReducer,

    courseUnits: courseUnitsReducer,
    courseUnit: courseUnitReducer,
    users: userReducer,
    registration: userRegistrationReducer,
    selected: selectedItemsReducer,
    newDocument: newDocumentSlice,
    documents: documentsSlice,
    document: documentSlice,
    deleteDoc: deleteDocSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;

const isLocalhost = process.env.NODE_ENV === 'development';
const PRODUCTION_BASE_URL = process.env.REACT_APP_PRODUCTION_BASE_URL;

export const baseUrl = isLocalhost
  ? 'http://127.0.0.1:8000/api/v1' // LOCAL ENVIRONMENT
  : PRODUCTION_BASE_URL; // PRODUCTION ENVIRONMENT

export const YOUTUBE_API_KEY = process.env.REACT_APP_YOUTUBE_API_KEY;

export const CURRENT_APP_VERSION = '1.2.115'; // Please update this version number when you make a new release(merge dev to main)

export const FIREBASE_NOTIFICATIONS_SERVER_KEY =
  process.env.REACT_APP_FIREBASE_NOTIFICATIONS_SERVER_KEY;

export const userRoles = {
  ADMIN: 'admin',
  SUPER_ADMIN: 'super admin',
  STUDENT: 'student',
  COORDINATOR: 'coordinator',
  UNIVERSITY_COORDINATOR: 'university coordinator',
  COURSE_COORDINATOR: 'course coordinator',
  COURSE_UNIT_COORDINATOR: 'course unit coordinator',
  CONTENT_COORDINATOR: 'content coordinator',
  LECTURER: 'lecturer',
  TUTOR: 'tutor',
  INSPIRATIONAL_MANAGER: 'ic editor',
};

export const ACCESS_POINT_UNIT_COST = process.env.REACT_APP_GPA_ACCESS_POINT_UNIT_COST;

export const DOCUMENT_CATEGORIES = [
  {
    name: 'Lecture Notes',
    link: 'lecture-notes',
  },
  {
    name: 'Text Books',
    link: 'text-books',
  },
  {
    name: 'Past Papers',
    link: 'past-papers',
  },
  {
    name: 'Question Banks',
    link: 'question-banks',
  },
  {
    name: 'Summaries',
    link: 'summaries',
  },
  {
    name: 'Video lectures',
    link: 'video-lectures',
  },
  {
    name: 'Assignments',
    link: 'assignments',
  },
];

export const VIDEO_TYPES = {
  YOUTUBE: 'youtube',
  VIMEO: 'vimeo',
  CUSTOM: 'custom',
};

export const UNIVERSITY_EMAIL_DOMAINS = [
  {
    name: 'makerere-university',
    domain: 'https://www.mak.ac.ug/',
  },
  {
    name: 'kyambogo-university',
    domain: 'https://kyu.ac.ug/',
  },
  {
    name: 'kampala-international-university',
    domain: 'https://kiu.ac.ug/',
  },
  {
    name: 'uganda-christian-university',
    domain: 'https://umu.ac.ug/',
  },
  {
    name: 'kampala-university',
    domain: 'https://www.ku.ac.ug/',
  },
  {
    name: 'bugema-university',
    domain: 'https://www.bugemauniv.ac.ug/',
  },
  {
    name: 'ndejje-university',
    domain: 'https://www.ndejjeuniversity.ac.ug/',
  },
  {
    name: 'mbarara-university-of-science-and-technology',
    domain: 'https://www.must.ac.ug/',
  },
  {
    name: 'busitema-university',
    domain: 'https://www.busitema.ac.ug/',
  },
  {
    name: 'gulu-university',
    domain: 'https://www.gu.ac.ug/',
  },
  {
    name: 'muni-university-of-technoscience',
    domain: 'https://www.muni.ac.ug/',
  },
  {
    name: 'soroti-university',
    domain: 'https://www.sun.ac.ug/',
  },
  {
    name: 'kumi-university',
    domain: 'https://www.kumiuniversity.ac.ug/',
  },
  {
    name: 'kabale-university',
    domain: 'https://www.kab.ac.ug/',
  },
  {
    name: 'team-university',
    domain: 'https://teamuniversity.ac.ug/',
  },
  {
    name: 'lira-university',
    domain: 'https://lirauni.ac.ug/',
  },
  {
    name: 'mountains-of-the-moon-university',
    domain: 'http://mmu.ac.ug/',
  },
  {
    name: 'nkumba-university',
    domain: 'https://nkumbauniversity.ac.ug/',
  },
  {
    name: 'kayiwa-international-university',
    domain: 'https://kintu.ac.ug/',
  },
  {
    name: 'muteesa-i-royal-university',
    domain: 'https://www.mru.ac.ug/',
  },
  {
    name: 'islamic-university-in-uganda',
    domain: 'https://www.iuiu.ac.ug/',
  },
  {
    name: 'victoria-university',
    domain: 'https://vu.ac.ug/',
  },
  {
    name: 'isbat-university',
    domain: 'https://isbatuniversity.ac.ug/',
  },
  {
    name: 'st.-lawrence-university',
    domain: 'https://slau.ac.ug/',
  },
  {
    name: 'makerere-university-business-school',
    domain: 'https://mubs.ac.ug/',
  },
  {
    name: 'cavendish-university-uganda',
    domain: 'https://www.cavendish.ac.ug/',
  },
];

export const TRANSACTION_STATUS = {
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
};

import React from 'react';
import { useSelector } from 'react-redux';
import QuickLinkMenu from './QuickLinkMenu';

import classes from "../AboutUsSection/AboutSection.module.css";
import styles from "../FooterMenu/FooterNavigation.module.css";

const QuickLinkSection = () => {
    const DarkMode = useSelector((state) => state.theme.darkMode);
    return (
      <nav
        className={`${styles.gpa__footer_navigation} ${
          classes.gpa__default_footer_navigation
        } ${DarkMode ? classes.gpa__dark_mode : ''}`}
      >
        <ul>
          <QuickLinkMenu />
        </ul>
      </nav>
    )
  };

export default QuickLinkSection;
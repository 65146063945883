import {
  authRequests,
  documentRequests,
  courseRequests,
  universityRequests,
  notificationRequests,
  courseUnitRequests,
} from './apiRequests';

/**
 * Handle all the API calls to gpa backend
 * @name requestAdapter
 * @param {(config: RequestConfig)=> any} requestor
 */
const requestAdapter = (requestor) => ({
  auth: authRequests(requestor),
  document: documentRequests(requestor),
  course: courseRequests(requestor),
  university: universityRequests(requestor),
  courseUnit: courseUnitRequests(requestor),
  notification: notificationRequests(requestor),
});

export default requestAdapter;
